// Librairies
import { Grid, Box, List, TextField, Typography, InputAdornment, IconButton, Modal, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ToggleButtonGroup, ToggleButton } from '@mui/material';
import QRCode from "react-qr-code";
import { Store } from 'react-notifications-component';
import { isMobile } from 'react-device-detect';
import { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useBlocker } from 'react-router-dom';
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";
import { useTranslation, Trans } from 'react-i18next';
import { firestore } from '../inc/config';
import { getAuth } from "firebase/auth";

// Layouts
import { StandardLayout } from '../layouts/standard/StandardLayout';
import { MiniStatus } from '../components/players/MiniStatus';
import { SearchTrackItem, SearchArtistItem, SearchAlbumItem, PlaylistTrackItem } from '../components/playlist/TrackItems';

// Icons
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import ShareIcon from '@mui/icons-material/Share';
import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ChatIcon from '@mui/icons-material/Chat';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
// Import ../assets/images/spotify-logo.svg as SpotifyLogo as rect component
import { ReactComponent as SpotifyLogo } from '../assets/images/spotify-logo.svg';
import { ReactComponent as SpotifyGlyphe  } from '../assets/images/spotify-glyphe.svg';

// Functions
import { transport } from '../inc/api.js';
import { reorderTracks, subscribeToPlaylist } from '../inc/Playlist';
import { getRecommendations } from '../inc/Spotify';
import { getCurrentUser, subscribeToPlaylistMessages } from '../inc/User';

export default function Playlist() {
    const { t } = useTranslation();
    const { playlistId } = useParams();
    const [ playlist, setPlaylist ] = useState(null);
    const [ keyword, setKeyword ] = useState('');
    const [ trackResults, setTrackResults ] = useState([]);
    const [ artistResults, setArtistResults ] = useState([]);
    const [ albumResults, setAlbumResults ] = useState([]);
    const [ searchMode, setSearchMode ] = useState('track');
    const [ loading, setLoading ] = useState(false);
    const [ loadingRecos, setLoadingRecos ] = useState(false);
    const [ displayShareModal, setDisplayShareModal ] = useState(false);
    const [ displaySubscribeModal, setDisplaySubscribeModal ] = useState(false);
    const [ userPlaylistStatus, setUserPlaylistStatus ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ displayScrollToTop, setDisplayScrollToTop ] = useState(false);
    const [ showDetails, setShowDetails ] = useState(null);
    const [ showPlayerDetails, setShowPlayerDetails ] = useState(false);
    const [ displaySearch, setDisplaySearch ] = useState(false);
    const messaging = getMessaging();
    const navigate = useNavigate();
    const searchTimer = useRef(null);
    const db = firestore();

    const requestPermission = async () => {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                // console.log('Notification permission granted.');
                getToken(messaging, {vapidKey: "BBtLyRcCJWFTikz8qQEv1cHn7KJTlJ5mO9LsXQFqHzmAHsTP6RaKlt6A304ueVTYd2pqTBTuiU5RXbzFgPIDWI8"}).then((currentToken) => {
                    if (currentToken) {
                        // console.log('currentToken', currentToken);
                        // Get user from firestore & check if topics[playlistId] exists
                        getDoc(doc(db, "users", getAuth().currentUser.uid)).then((doc) => {
                            if(!doc.exists()) {
                                subscribeToPlaylistMessages(playlistId, currentToken);
                            } else {
                                if(doc.data().topics && doc.data().topics[playlistId]) {
                                    console.log('Topic already managed.');
                                } else {
                                    // Subscribe to playlist messages
                                    subscribeToPlaylistMessages(playlistId, currentToken);
                                }
                            }
                        });
                    } else {
                        // Show permission request UI
                        console.log('No registration token available. Request permission to generate one.');
                        // ...
                    }
                    }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                    // ...
                });
            } else {
                console.log('Unable to get permission to notify.');
            }
        });
    }

    const searchTracks = () => {
        setLoading(true);
        setDisplaySearch(true);
        if(searchMode !== 'recos') setTrackResults([])
        setAlbumResults([])
        setArtistResults([])
        transport(`/spotify/${playlistId}/search?q=${keyword}`).then((result) => {
            setSearchMode('tracks');
            if(result.success === true) {
                setTrackResults(result.tracks)
                setAlbumResults(result.albums)
                setArtistResults(result.artists)
                setLoading(false);
            } else {
                setTrackResults([])
                setAlbumResults([])
                setArtistResults([])
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err)
            setSearchMode('tracks');
            setTrackResults([])
            setAlbumResults([])
            setArtistResults([])
            setLoading(false);
        });
    }

    // Block navigation & setShowDetails to false on back button pressed.
    useBlocker(({currentLocation, nextLocation, historyAction}) => {
        if(historyAction === 'POP' && showDetails !== null) {
            setShowDetails(null)
            return true;
        } else if (historyAction === 'POP' && showPlayerDetails === true) {
            setShowPlayerDetails(false)
            return true;
        } else {
            return false;
        }
    }, [showDetails])

    useEffect(() => {
        if(searchTimer.current) clearTimeout(searchTimer.current);
        searchTimer.current = setTimeout(() => {
            if(keyword.length === 0) return;
            searchTracks();
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyword]);

    useEffect(() => {
        getCurrentUser().then((userData) => {
            setUser(userData);
        }).catch((err) => {
            console.log(err);
        });
        requestPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "playlists", playlistId), async (doc) => {
            let playlistData = doc.data();
            playlistData.id = doc.id;
            const reorderedTracks = await reorderTracks(playlistData.tracks);
            playlistData.tracks = reorderedTracks;
            setPlaylist(playlistData);

            Object.keys(playlistData.subscribers).map((subscriber) => {
                if(subscriber === user.uid) {
                    setUserPlaylistStatus(playlistData.subscribers[subscriber].status);
                }
                return subscriber;
            });
        }, async (error) => {
            console.log("Error getting document:", error);
            if (error.code === 'permission-denied') {
                // Handle permission denied error
                setDisplaySubscribeModal(true);
            }
        });

        return () => unsub();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`${window.location.href}`)
        Store.addNotification({
            title: t('playlist.copyToClipboardTitle'),
            // message: t('playlist.copyToClipboardMessage'),
            type: 'success',
            insert: 'bottom',
            container: 'bottom-center',
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true
            }
        });
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '0px',
        boxShadow: 24,
        p: 4,
    };

    const handleSubscribe = async () => {
        // Subscribe to playlist
        setLoading(true);
        await subscribeToPlaylist(playlistId);
        window.location.reload();
    }

    const handleRecos = () => {
        setLoadingRecos(true);
        getRecommendations(playlistId).then((recos) => {
            setLoadingRecos(false);
            setTrackResults(recos);
            setSearchMode('recos');
        }).catch((err) => {
            console.log(err);
            setLoadingRecos(false);
            setTrackResults([]);
            setSearchMode('tracks');
        });
    }

    const handleScroll = () => {
        if (window.scrollY > 230) {
            setDisplayScrollToTop(true);
        } else {
            setDisplayScrollToTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if(userPlaylistStatus === 'bloqued') navigate('/');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPlaylistStatus]);

    return <StandardLayout title={playlist && playlist.name && playlist.name} backButton={'home'} addBottomPadding={true}>
        <Modal open={displayShareModal} onClose={() => setDisplayShareModal(false)}>
            <Box sx={modalStyle}>
                <HighlightOffIcon sx={{position: 'absolute', top: 10, right: 10, cursor: 'pointer'}} onClick={() => setDisplayShareModal(false)} />
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <h2 style={{margin: 0}}>{t('playlist.shareTitle')}</h2>
                    <p>{t('playlist.shareDescription')}</p>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', backgroundColor: 'white', p: '1em'}}>
                        <QRCode value={`${window.location.href}`} />
                    </Box>
                    <Box sx={{width: '100%', mt: '1.5em'}}>
                        <TextField 
                            label={t('playlist.shareUrl')}
                            fullWidth
                            value={`${window.location.href}`}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={copyToClipboard}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    {isMobile && <Box sx={{width: '100%', mt: '1.5em'}}>
                        <Button variant="outlined" fullWidth onClick={() => navigator.share({url: `${window.location.href}`, text: t('playlist.shareText')})}>{t('playlist.mobileShare')}</Button>
                    </Box>}
                </Box>
            </Box>
        </Modal>
        <Dialog open={displaySubscribeModal} onClose={() => setDisplaySubscribeModal(false)}>
            <DialogTitle>{t('playlist.subscribeTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText><Trans i18nKey="playlist.subscribeText" values={{ playlist: playlist?.name }} /></DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDisplaySubscribeModal(false)} color="primary">{t('common.cancel')}</Button>
                <Button onClick={handleSubscribe} color="primary">{t('playlist.subscribeConfirm')}</Button>
            </DialogActions>
        </Dialog>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', pb: 1}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <IconButton onClick={() => setDisplayShareModal(true)}>
                    <ShareIcon style={{fontSize: 32}} />
                </IconButton>
                <IconButton onClick={() => navigate(`/playlist/${playlistId}/subscribers`)}>
                    <EmojiEventsIcon style={{fontSize: 32}} />
                </IconButton>
                <IconButton onClick={() => navigate(`/playlist/${playlistId}/messenger`)}>
                    {
                        playlist && 
                        playlist.subscribers && 
                        playlist.lastMessage &&
                        playlist.subscribers[user.uid] && 
                        playlist.subscribers[user.uid].lastMessageView &&
                        playlist.subscribers[user.uid].lastMessageView.toDate() < playlist.lastMessage.toDate() ? 
                        <MarkUnreadChatAltIcon style={{fontSize: 32}} /> : <ChatIcon style={{fontSize: 32}} />}
                </IconButton>
                <IconButton onClick={() => navigate(`/playlist/${playlistId}/settings`)}>
                    <SettingsIcon style={{fontSize: 32}} />
                </IconButton>
                {userPlaylistStatus === 'owner' && <>
                    <IconButton onClick={() => document.location.href = `/playlist/${playlistId}/queue`}>
                        <PlayCircleOutlineIcon style={{fontSize: 32}} />
                    </IconButton>
                </>}
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={() => window.open('https://open.spotify.com/')}>
                {userPlaylistStatus === 'owner' ? <SpotifyGlyphe style={{ height: 30, width: 30 }} /> : <SpotifyLogo style={{ height: 30, width: 90 }} />}
            </Box>
        </Box>
        <Box>
            <form onSubmit={(e) => { e.preventDefault(); }}>
                <TextField 
                    label={t('playlist.searchTitle')} 
                    fullWidth 
                    autoComplete='off'
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onFocus={() => {
                        setDisplaySearch(true);
                        return keyword.length === 0 && handleRecos()
                    }}
                    onBlur={() => {
                        if(!trackResults || trackResults.length === 0) {
                            setDisplaySearch(false);
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {((keyword && keyword.length > 0) || (trackResults && trackResults.length > 0)) && <IconButton onClick={(e) => { 
                                    setDisplaySearch(false);
                                    setKeyword(''); 
                                    setTrackResults([])
                                    setAlbumResults([])
                                    setArtistResults([])
                                }}>
                                    <HighlightOffIcon />
                                </IconButton>}
                                <IconButton type="submit" onClick={searchTracks}>
                                    {loading ? <CircularProgress size={24} /> : <SearchIcon color="primary" />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </form>
        </Box>
        {displaySearch ? <Box sx={{mt: 2}}>
            {loadingRecos && <Box>
                <Typography variant="h6">{t('playlist.recommendationsCalculation')}</Typography>
            </Box>}
            {trackResults && trackResults.length > 0 && searchMode === 'recos' && <Box>
                <Typography variant="h6">{t('playlist.recommendations')}</Typography>
            </Box>}
            {trackResults && trackResults.length > 0 && searchMode !== 'recos' && <ToggleButtonGroup fullWidth color="primary" exclusive onChange={(event, newAlignment) => {
                setSearchMode(newAlignment)
            }} value={searchMode}>
                <ToggleButton value="tracks">{t('playlist.tracks')}</ToggleButton>
                <ToggleButton value="artists">{t('playlist.artists')}</ToggleButton>
                <ToggleButton value="albums">{t('playlist.albums')}</ToggleButton>
            </ToggleButtonGroup>}
            {searchMode === 'artists' && artistResults && artistResults.length > 0 && <Grid container>
                {artistResults.map((artist) => (
                    <Grid item xs={6} md={3} key={artist.id} sx={{mt: 2, p: 1, cursor: 'pointer', display: 'flex', justifyContent: 'center'}} onClick={(e) => { setKeyword(`artist:"${artist.name}"`); }}>
                        <SearchArtistItem artist={artist} />
                    </Grid>
                ))}
            </Grid>}
            {searchMode === 'albums' && albumResults && albumResults.length > 0 && <Grid container>
                {albumResults.map((album) => (
                    <Grid item xs={6} md={3} key={album.id} sx={{mt: 2, p: 1, cursor: 'pointer', display: 'flex', justifyContent: 'center'}} onClick={(e) => { setKeyword(`album:"${album.name}" artist:"${album.artists.map((artist) => artist.name).join(', ')}"`); }}>
                        <SearchAlbumItem album={album} />
                    </Grid>
                ))}
            </Grid>}
            {['tracks', 'recos'].includes(searchMode) && <Box>
                <List style={{ width: '100%' }}>
                    {trackResults.map((track) => {
                        if(track && track.id) {
                            return <Box key={track.id}>
                                <SearchTrackItem setDisplaySearch={setDisplaySearch} track={track} playlistId={playlistId} playlist={playlist} setTrackResults={setTrackResults} />
                            </Box>
                        } else {
                            return null;
                        }
                    })}
                </List>
            </Box>}
        </Box> : <Box>
            <List>
                {playlist && playlist.tracks && Object.keys(playlist.tracks).map((track, position) => (
                    <Box key={track} id={track}>
                        <PlaylistTrackItem setDisplaySearch={setDisplaySearch} position={position+1} trackId={track} track={playlist.tracks[track]} playlistId={playlistId} playlist={playlist} setTrackResults={setTrackResults} setShowDetails={setShowDetails} showDetails={showDetails} />
                    </Box>
                ))}
            </List>
        </Box>}
        {displayScrollToTop && <Box sx={{position: 'fixed', bottom: playlist.playback && playlist.playback.is_playing && (new Date().getTime() - playlist.playback.updated.toDate().getTime()) < playlist.playback.duration ? 120 : 20, left: 20, zIndex: 700}}>
            <IconButton sx={{ backgroundColor: "#00DE83", zIndex: 700 }} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                <KeyboardArrowUpIcon style={{ fontSize: 50 }} />
            </IconButton>
        </Box>}
        <MiniStatus playlist={playlist} showPlayerDetails={showPlayerDetails} setShowPlayerDetails={setShowPlayerDetails} />
    </StandardLayout>;
}