import { firestore } from "./config";
import { getCurrentUser } from "./User.js";
import { getDocs, getDoc, doc, addDoc, updateDoc, deleteDoc, collection, query, where, increment, deleteField } from "firebase/firestore";
import { transport } from "./api.js";
import DefaultProfile from '../assets/images/default-user.jpg'

/**
 * Get playlists of the current user
 */
export const getUserPlaylists = () => {
    return new Promise(async (resolve, reject) => {
        const db = firestore();
        const user = await getCurrentUser();
        const q = query(collection(db, "playlists"), where("owner", "==", user.uid));
        getDocs(q).then((querySnapshot) => {
            const playlists = [];
            querySnapshot.forEach((doc) => {
                playlists.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            resolve(playlists);
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * Creates a new playlist
 * @param {string} playlistName 
 * @returns docRef.id
 */
export const createPlaylist = (playlistName) => {
    return new Promise(async (resolve, reject) => {
        const db = firestore();
        const user = await getCurrentUser();
        
        addDoc(collection(db, "playlists"), {
            name: playlistName,
            owner: user.uid,
            image: "https://storage.googleapis.com/partyfy-io-app.appspot.com/public/album.jpg",
            tracks: {},
            subscribers: {
                [user.uid]: {
                    added: new Date(),
                    display_name: user.displayName ? user.displayName : "Anonymous",
                    picture: user.photoURL ? user.photoURL : DefaultProfile,
                    status: "owner"
                }
            },
            created: new Date(),
            updated: new Date()
        }).then((docRef) => {
            resolve(docRef.id);
        }).catch((error) => {
            reject(error);
        })
    });
}

/**
 * Gets a playlist
 * @param {string} playlistId
 */
export const getPlaylist = (playlistId) => {
    return new Promise((resolve, reject) => {
        const db = firestore();
        getDoc(doc(db, "playlists", playlistId)).then((doc) => {
            resolve(doc.data());
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * Removes a playlist
 * @param {string} playlistId
 */
export const removePlaylist = (playlistId) => {
    return new Promise((resolve, reject) => {
        const db = firestore();
        deleteDoc(doc(db, "playlists", playlistId)).then(() => {
            deleteDoc(doc(db, "messenger", playlistId)).then(() => {
                resolve();
            }).catch((error) => {
                reject(error);
            });
        }).catch((error) => {
            reject(error);
        })
    });
}

/**
 * Update playlist infos
 */
export const updatePlaylist = (playlistId, data) => {
    return new Promise((resolve, reject) => {
        const db = firestore();
        updateDoc(doc(db, "playlists", playlistId), data).then(() => {
            resolve();
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * Set status bloqued to subscriber
 */
export const changeSubscriberStatus = (playlistId, subscriberId, status) => {
    return new Promise(async (resolve, reject) => {
        const db = firestore();
        updateDoc(doc(db, "playlists", playlistId), {
            [`subscribers.${subscriberId}.status`]: status
        }).then(() => {
            resolve();
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * Adds a track to a playlist
 * @param {string} playlistId
 * @param {string} trackId
 * @returns
*/
export const addTrackToPlaylist = (playlistId, playlist, track) => {
    return new Promise(async (resolve, reject) => {
        const db = firestore();
        const user = await getCurrentUser();
        
        const playlistRef = doc(db, "playlists", playlistId);
        
        // If track is already in playlist, just addVoteToTrack, otherwise add track
        if(playlist.tracks[track.id]) {
            addVoteToTrack(playlistId, track.id).then(async () => {
                return resolve();
            }).catch((error) => {
                console.log('error adding vote to track', error)
                return reject(error);
            });
            return;
        } else {
            updateDoc(playlistRef, {
                updated: new Date(),
                [`tracks.${track.id}`]: {
                    added: new Date(),
                    addedby: {
                        uid: user.uid,
                        display_name: user.displayName ? user.displayName : "Anonymous",
                        picture: user.photoURL ? user.photoURL : DefaultProfile
                    },
                    created: false,
                    note: 2,
                    trackData: {
                        name: track.name,
                        artists: track.artists.map((artist) => artist.name),
                        album: track.album.name,
                        albumArt: track.album && track.album.images && track.album.images[0] && track.album.images[0].url ? track.album.images[0].url : "https://storage.googleapis.com/partyfy-io-app.appspot.com/public/album.jpg",
                        spotifyId: track.id,
                        spotifyUrl: track.external_urls.spotify,
                        spotifyUri: track.uri,
                        preview_url: track.preview_url
                    },
                    votes: {
                        [user.uid]: {
                            added: new Date(),
                            display_name: user.displayName ? user.displayName : "Anonymous",
                            picture: user.photoURL ? user.photoURL : DefaultProfile,
                            value: 1
                        }
                    }
                },
                [`subscribers.${user.uid}.tracks`]: increment(1),
                [`subscribers.${user.uid}.votes`]: increment(1)
            }).then(async () => {
                // await transport(`/spotify/${playlistId}/sync`)
                return resolve();
            }).catch((error) => {
                console.log('error adding track', error)
                return reject(error);
            });
        }
    });
}

/**
 * Removes a track from a playlist
 * @param {string} playlistId
 * @param {string} trackId
 * @returns
 * */
export const removeTrackFromPlaylist = (playlistId, trackId) => {
    return new Promise((resolve, reject) => {
        // console.log('removeTrackFromPlaylist', playlistId, trackId)
        const db = firestore();
        updateDoc(doc(db, "playlists", playlistId), {
            updated: new Date(),
            [`tracks.${trackId}`]: deleteField()
        }).then(() => {
            resolve();
        }).catch((error) => {
            reject(error);
        })
    });
}

/**
 * Adds a vote to a track
 * @param {string} playlistId
 * @param {string} trackId
 * @returns
 */
export const addVoteToTrack = (playlistId, trackId) => {
    return new Promise(async (resolve, reject) => {
        const db = firestore();
        const user = await getCurrentUser();
        updateDoc(doc(db, "playlists", playlistId), {
            updated: new Date(),
            [`tracks.${trackId}.votes.${user.uid}`]: {
                added: new Date(),
                display_name: user.displayName,
                picture: user.photoURL ? user.photoURL : DefaultProfile,
                value: 1
            }
        }).then(() => {
            resolve();
        }).catch((error) => {
            reject(error);
        })
    });
}

/**
 * Removes a vote from a track
 * @param {string} playlistId
 * @param {string} trackId
 * @returns
 * */
export const removeVoteFromTrack = (playlistId, trackId) => {
    return new Promise(async (resolve, reject) => {
        const db = firestore();
        const user = await getCurrentUser();
        updateDoc(doc(db, "playlists", playlistId), {
            updated: new Date(),
            [`tracks.${trackId}.votes.${user.id}`]: deleteField()
        }).then(() => {
            resolve();
        }).catch((error) => {
            reject(error);
        })
    });
}

/**
 * Sync playlist with Spotify
 * @param {string} playlistId
 */
export const syncDistantPlaylist = (playlistId) => {
    return new Promise(async (resolve, reject) => {
        transport(`/spotify/${playlistId}/sync/dist`).then(() => {
            resolve();
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * Import tracks from spotify playlist
 * @param {string} playlistId
 * @param {string} spotifyId
 */
export const importTracksFromSpotify = (playlistId, spotifyId) => {
    return new Promise(async (resolve, reject) => {
        transport(`/spotify/${playlistId}/import/${spotifyId}`).then((result) => {
            if(result.success) {
                resolve();
            } else {
                reject(new Error('Unable to import tracks'));
            }
        }).catch((error) => {
            reject(error);
        });
    });

}

/**
 * Get playback state from spotify
 * @param {string} playlistId 
 * @returns object playbackstate
 */
export const getPlaybackState = (playlistId) => {
    return new Promise(async (resolve, reject) => {
        transport(`/spotify/${playlistId}/playback`).then((result) => {
            resolve(result);
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * reorder tracks
 * @param {object} tracks 
 * @returns {object} tracks 
 */
export const reorderTracks = (tracks) => {
    return new Promise(async (resolve, reject) => {
        return resolve(Object.entries(tracks).sort((a, b) => {
            let sumA = 0;
            let sumB = 0;
            let aSeconds = 0;
            let bSeconds = 0;

            if(a[1].votes) {
                Object.keys(a[1].votes).forEach((user) => {
                    sumA += a[1].votes[user].value;
                });
            }
            if(b[1].votes) {
                Object.keys(b[1].votes).forEach((user) => {
                    sumB += b[1].votes[user].value;
                });
            }

            aSeconds += a[1].added.seconds;
            bSeconds += b[1].added.seconds;

            const aNameNumbner = a[1].trackData.name.stringToNumber();
            const bNameNumbner = b[1].trackData.name.stringToNumber();
            aSeconds = parseFloat(aSeconds.toString() + "." + aNameNumbner.toString());
            bSeconds = parseFloat(bSeconds.toString() + "." + bNameNumbner.toString());

            aSeconds = aSeconds/10000000000;
            bSeconds = bSeconds/10000000000;

            sumA -= aSeconds;
            sumB -= bSeconds;

            return sumB - sumA;
        }).reduce((obj, [key, val]) => {
            obj[key] = val;
            return obj;
        }, {}));
    });
}

/**
 * Subscribe to playlist
 * @param {string} playlistId
 */
export const subscribeToPlaylist = (playlistId) => {
    return new Promise(async (resolve, reject) => {
        transport(`/spotify/${playlistId}/subscribe`, 'GET').then(() => {
            resolve();
        }).catch((error) => {
            reject(error);
        });
    });
}

/**
 * Get playlist Spotify credentials
 */
export const getSpotifyCredentials = (playlistId, userId) => {
    return new Promise(async (resolve, reject) => {
        getDoc(doc(firestore(), "credentials", userId)).then((doc) => {
            let data = doc.data();
            if(data.expires_in.toDate() < new Date()) {
                transport(`/spotify/${playlistId}/token/refresh`, 'GET').then((result) => {
                    if(result.success) {
                        return resolve(result.token);
                    } else {
                        return reject(new Error('Unable to refresh token'));
                    }
                }).catch((error) => {
                    return reject(error);
                });
            } else {
                return resolve(data.access_token);
            }
        }).catch((error) => {
            return reject(error);
        });
    });
}