import { firestore } from "./config";
import { getAuth } from "firebase/auth";
import { getDoc, doc, setDoc, updateDoc, collection, query, getDocs, where } from "firebase/firestore";
import { transport } from "./api";
import DefaultProfile from '../assets/images/default-user.jpg'

/**
 * Check authentication, store informations if needed onto firestore
 */
export const checkAuth = () => {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        const db = firestore();

        if(!auth.currentUser) {
            return resolve(false);
        } else {
            const docRef = doc(db, "users", auth.currentUser.uid)
            getDoc(docRef).then((doc) => {
                if(doc.exists()) {
                    updateDoc(docRef, {
                        displayName: auth.currentUser.displayName,
                        photoURL: auth.currentUser.photoURL ? auth.currentUser.photoURL : DefaultProfile,
                    }).then(async () => {
                        const updatedDoc = await getDoc(docRef);
                        localStorage.setItem('user', JSON.stringify(updatedDoc.data()));
                        return resolve(true);
                    }).catch((error) => {
                        console.log(error);
                        return resolve(false);
                    });
                } else {
                    const docData = {
                        uid: auth.currentUser.uid,
                        email: auth.currentUser.email,
                        displayName: auth.currentUser.displayName,
                        photoURL: auth.currentUser.photoURL ? auth.currentUser.photoURL : DefaultProfile,
                    }
                    setDoc(docRef, docData).then(() => {
                        localStorage.setItem('user', JSON.stringify(docData));
                        return resolve(true);
                    }).catch((error) => {
                        console.log(error);
                        return resolve(false);
                    });
                }
            }).catch((error) => {
                console.log("Here", error);
                return resolve(false);
            });
        }
    });
}

/**
 * Get spotify credentials from firestore for user if exists
 */
export const getSpotifyCredentials = () => {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        const db = firestore();

        if(!auth.currentUser) {
            return resolve(false);
        } else {
            const docRef = doc(db, "credentials", auth.currentUser.uid);
            getDoc(docRef).then((doc) => {
                if(doc.exists()) {
                    return resolve(doc.data());
                } else {
                    return resolve(false);
                }
            }).catch((error) => {
                console.log(error);
                return resolve(false);
            });
        }
    });
}

/**
 * Get current users infos
 * @returns current user infos
 */
export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        if(!auth.currentUser) {
            return reject(new Error('User not found'));
        } else {
            return resolve(auth.currentUser);
        }
    })
}

/**
 * Where user updates him profile, update data in firestore : subscribers.id.display_name, subscribers.id.picture tracks.{id}.addedby.display_name, tracks.{id}.addedby.picture, 
 * @param {string} playlistId
 */
export const updatePlaylistUserInfos = async () => {
    return new Promise(async (resolve, reject) => {
        const db = firestore();
        const user = await getCurrentUser();

        // Get playlist where user id in subscribers keys
        const PlaylistQuery = query(collection(db, "playlists"), where("subscribers." + user.uid + ".status", "in", ['subscriber', 'owner']));
        const PlaylistSnapshot = await getDocs(PlaylistQuery);
        PlaylistSnapshot.forEach(async (doc) => {
            const playlist = doc.data();
            let playlistUpdate = {};
            
            // Update subscribers info
            if(playlist.subscribers[user.uid]) {
                playlistUpdate["subscribers." + user.uid + ".display_name"] = user.displayName;
                playlistUpdate["subscribers." + user.uid + ".picture"] = user.photoURL ? `${user.photoURL}?t=${new Date().getTime()}` : `${DefaultProfile}?t=${new Date().getTime()}`;
            }

            // Update playlist
            await updateDoc(doc.ref, playlistUpdate);
        });
        return resolve(true);
    });
}

/**
 * Subscribe user to playlist
 */
export const subscribeToPlaylistMessages = async (playlistId, token) => {
    return new Promise(async (resolve, reject) => {
        transport(`/messaging/${playlistId}/subscribe`, 'GET', {
            token: token,
        }).then(async (response) => {
            console.log(response);
            return resolve(response);
        }).catch((error) => {
            console.log(error);
            return reject(error);
        });
    });
}

export const unsubscribeToPlaylistMessages = async (playlistId, token) => {
    return new Promise(async (resolve, reject) => {
        transport(`/messaging/${playlistId}/unsubscribe`, 'GET', {
            token: token,
        }).then(async (response) => {
            console.log(response);
            return resolve(response);
        }).catch((error) => {
            console.log(error);
            return reject(error);
        });
    });
}
