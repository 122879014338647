import { useEffect } from 'react';

// import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

// ReactGA.initialize('G-D4T1XNL361');

export const Analytics = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: document.location.pathname,
            page_location: document.location.href,
        });
    }, [location]);

    return children;
}