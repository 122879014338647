import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { getAuth } from "firebase/auth";
import { firestore } from '../inc/config.js';
import { collection, query, where, getDocs, doc, deleteDoc } from "firebase/firestore";

import { GlobalContext } from '../inc/GlobalContext';
import { StandardLayout } from '../layouts/standard/StandardLayout';

export default function Account() {
    const { user } = useContext(GlobalContext);
    const auth = getAuth();
    const navigate = useNavigate();
    const db = firestore();
    const { t } = useTranslation();
    const [ displayDeleteConfirmation, setDisplayDeleteConfirmation ] = useState(false);
    const [ displayRelogin, setDisplayRelogin ] = useState(false);

    const handleDeleteAccount = async () => {
        // Delete playlists
        try {
            const q = query(collection(db, "playlists"), where("owner", "==", user.uid));
            const playlists = await getDocs(q);
            for(var i = 0; i < playlists.docs.length; i++) {
                let playlist = playlists.docs[i];
                // Delete doc
                await deleteDoc(doc(db, "playlists", playlist.id));
            }
        } catch(error) {
            console.log("DeletePlaylists", error);
        }

        // Delete credentials
        try {
            const docRef = doc(db, "credentials", user.uid);
            await deleteDoc(docRef);
        } catch(error) {
            console.log("DeleteCredentials", error);
        }

        // Delete user
        try {
            await auth.currentUser.delete();
        } catch(error) {
            console.log("DeleteUser", error.message);
            if(error.message === 'Firebase: Error (auth/requires-recent-login).') {
                setDisplayDeleteConfirmation(false);
                setDisplayRelogin(true);
            }
        }

        // Redirect to home
        // window.location.href = '/';
    };

    const handleLogout = async () => {
        await auth.signOut();
        navigate('/auth')
    }

    return <StandardLayout title={t('account.title')}>
        <Dialog
            open={displayRelogin}
            onClose={() => setDisplayRelogin(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('account.reloginTitle')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('account.reloginText')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={handleLogout} autoFocus>{t('common.confirm')}</Button>
            </DialogActions>
        </Dialog>
        <Dialog
            open={displayDeleteConfirmation}
            onClose={() => setDisplayDeleteConfirmation(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {t('account.deleteConfirmationTitle')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('account.deleteConfirmationText')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => setDisplayDeleteConfirmation(false)}>{t('common.cancel')}</Button>
                <Button color="error" onClick={handleDeleteAccount} autoFocus>{t('common.confirm')}</Button>
            </DialogActions>
        </Dialog>
        <TextField value={user.email} disabled variant="outlined" fullWidth label={t('account.email')} />
        <Button variant='contained' color="error" onClick={() => setDisplayDeleteConfirmation(true)} fullWidth style={{ marginTop: '2rem'}}>{t('account.delete')}</Button>
    </StandardLayout>;
}