import React, { createContext, useState, useRef, useEffect } from 'react';
import { getAuth, updateProfile } from "firebase/auth";
import { uniqueNamesGenerator, adjectives, colors, animals } from 'unique-names-generator';


export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const auth = getAuth();
    const [ user, setUser ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ audioTrack, setAudioTrack ] = useState(null);
    const [ playbackState, setPlaybackState ] = useState(null);
    const audioPlayer = useRef(new Audio());

    useEffect(() => {
        if(audioTrack) {
            audioPlayer.current.pause();
            audioPlayer.current.src = audioTrack;
            audioPlayer.current.play();
            setPlaybackState('playing');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioTrack]);

    useEffect(() => {
        if(playbackState === 'playing') {
            audioPlayer.current.play();
        } else if(playbackState === 'paused') {
            audioPlayer.current.pause();
        } else if(playbackState === 'stopped') {
            audioPlayer.current.pause();
            audioPlayer.current.currentTime = 0;
        }
        // 
    }, [playbackState]);

    const authState = async () => {
        if(auth.currentUser) {
            if(!auth.currentUser.displayName) {
                const displayName = uniqueNamesGenerator({ dictionaries: [adjectives, colors, animals], separator: ' ' });
                await updateProfile(auth.currentUser, { displayName: displayName.capitalize() });
            }
            
            sessionStorage.setItem('currentUser', JSON.stringify(auth.currentUser));
            setUser(auth.currentUser);
            setLoading(false);
        } else {
            if(sessionStorage.getItem('currentUser')) sessionStorage.removeItem('currentUser');
            setUser(null);
            setLoading(false);
        }
    }

    useEffect(() => {
        auth.onAuthStateChanged(authState);
        audioPlayer.current.addEventListener('ended', () => {
            setPlaybackState('stopped');
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <GlobalContext.Provider value={{ user, setUser, loading, setLoading, audioTrack, setAudioTrack, playbackState, setPlaybackState }}>
            {children}
        </GlobalContext.Provider>
    );
};