import { Box } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const PlaylistItem = ({ playlist }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    return <Box sx={{ 
            width: 'calc(50% - 5px)',
            aspectRatio: '1/1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textTransform: 'uppercase',
            backgroundColor: 'primary.main',
            borderRadius: '5px',
            color: '#FFF',
            background: playlist.image ? `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${playlist.image})` : `linear-gradient(rgba(0, 222, 131, 0.5), rgba(0, 222, 131,0.5))`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer',
            padding: '5px',
        }} onClick={() => navigate(`/playlist/${playlist.id}`)}>
        <Link style={{
            color: '#FFF',
            textDecoration: 'none',
            fontWeight: 'bold',
            textAlign: 'center',
        }} to={`/playlist/${playlist.id}`}>{playlist.name}</Link>
        <small>{Object.keys(playlist.tracks).length} {t('playlist.tracks')}</small>
    </Box>
}