import React from 'react';
import { getAuth } from "firebase/auth";
import { Typography, MenuItem, Avatar, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import FaceIcon from '@mui/icons-material/Face';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import MenuIcon from '@mui/icons-material/Menu';

export default function AccountMenu({ displayMenu, setDisplayMenu }) {
    const navigate = useNavigate();
    const auth = getAuth();
    const { t } = useTranslation();

    const handleClose = () => {
        setDisplayMenu(false);
    };

    /** Logout from firebase and redirect to home */
    const handleLogout = async () => {
        await auth.signOut();
        setDisplayMenu(false);
        navigate('/auth')
    }

    return (
        <>
            <Box onClick={() => setDisplayMenu(false)} sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                display: displayMenu ? 'block' : 'none',
                zIndex: 999,
            }} />
            <Box className={displayMenu ? 'menu slide-in' : 'menu slide-out'} sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                bgcolor: 'white',
                p: 0.6,
                pr: 2,
                zIndex: 1000,
                boxShadow: 2,
                maxWidth: 300,
                width: '80%',
                height: '100vh',
                backgroundColor: '#121212',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}>
                    <Button onClick={(e) => setDisplayMenu(false)}>
                        <MenuIcon 
                            style={{ 
                                width: 50, 
                                height: 50, 
                                color: 'white',
                            }}
                        />
                    </Button>
                </Box>
                <MenuItem onClick={() => {
                    handleClose();
                    navigate('/'); 
                }}>
                    <Avatar sx={{ width: 32, height: 32, bgcolor: "#00DE83" }}><DashboardIcon /></Avatar>
                    <Typography variant="button" sx={{ ml: 2, fontSize: 18 }}>{t('menu.home')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    navigate('/profile'); 
                }}>
                    <Avatar sx={{ width: 32, height: 32, bgcolor: "#00DE83" }}><FaceIcon /></Avatar>
                    <Typography variant="button" sx={{ ml: 2, fontSize: 18 }}>{t('menu.profile')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    navigate('/account'); 
                }}>
                    <Avatar sx={{ width: 32, height: 32, bgcolor: "#00DE83" }}><ManageAccountsIcon /></Avatar>
                    <Typography variant="button" sx={{ ml: 2, fontSize: 18 }}>{t('menu.account')}</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    document.location.href = t('menu.helpUrl');
                }}>
                    <Avatar sx={{ width: 32, height: 32, bgcolor: "#00DE83" }}><HelpOutlineIcon /></Avatar>
                    <Typography variant="button" sx={{ ml: 2, fontSize: 18 }}>{t('menu.help')}</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <Avatar sx={{ width: 32, height: 32, bgcolor: "#00DE83" }}><PowerSettingsNewIcon /></Avatar>
                    <Typography variant="button" sx={{ ml: 2, fontSize: 18 }}>{t('menu.logout')}</Typography>
                </MenuItem>
            </Box>
        </>
    );
    
}