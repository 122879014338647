import { useEffect, useState } from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { doc, onSnapshot } from "firebase/firestore";
import { List, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, IconButton, Badge } from '@mui/material';

import { StandardLayout } from '../layouts/standard/StandardLayout';
import { firestore } from '../inc/config';
import { getCurrentUser } from '../inc/User';
import { reorderTracks, changeSubscriberStatus } from '../inc/Playlist';

import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';


export default function PlaylistSubscribers() {
    const db = firestore();
    const { t } = useTranslation();
    const { playlistId } = useParams();
    const [ playlist, setPlaylist ] = useState(null);
    const [ currentUser, setCurrentUser ] = useState(null);

    useEffect(() => {
        try {
            const unsub = onSnapshot(doc(db, "playlists", playlistId), async (doc) => {
                const user = await getCurrentUser();
                setCurrentUser(user);
                let playlistData = doc.data();
                playlistData.id = doc.id;
                const reorderedTracks = await reorderTracks(playlistData.tracks);
                playlistData.tracks = reorderedTracks;
                playlistData.isOwner = playlistData.owner === user.uid;

                // Sort playlist.subscribers by sum of votes & tracks sub value.
                let subscribers = Object.keys(playlistData.subscribers).sort((a, b) => {
                    if(!playlistData.subscribers[b].votes) playlistData.subscribers[b].votes = 0;
                    if(!playlistData.subscribers[b].tracks) playlistData.subscribers[b].tracks = 0;
                    if(!playlistData.subscribers[a].votes) playlistData.subscribers[a].votes = 0;
                    if(!playlistData.subscribers[a].tracks) playlistData.subscribers[a].tracks = 0;
                    return (playlistData.subscribers[b].votes + (playlistData.subscribers[b].tracks * 2)) - (playlistData.subscribers[a].votes + (playlistData.subscribers[a].tracks*2));
                });

                let sortedSubscribers = {};
                for(let i = 0; i < subscribers.length; i++) {
                    sortedSubscribers[subscribers[i]] = playlistData.subscribers[subscribers[i]];
                }
                
                playlistData.subscribers = sortedSubscribers;
                
                setPlaylist(playlistData);
            }, async (error) => {
                console.log("Error getting document:", error);
                if (error.code === 'permission-denied') {
                    // Handle permission denied error
                }
            });
    
            return () => unsub();
        } catch(e) {
            console.log(e)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBlock = async (subscriber) => {
        try {
            let r = window.confirm(t('playlist.subscribers.confirmBlock', { user: playlist.subscribers[subscriber].display_name }));
            if(!r) return;
            await changeSubscriberStatus(playlist.id, subscriber, playlist.subscribers[subscriber].status === 'bloqued' ? 'subscriber' : 'bloqued');
        } catch(e) {
            console.log(e)
        }
    }

    return <StandardLayout title={playlist && playlist.name && t('playlist.subscribers.title', { name: playlist?.name })} backButton={`/playlist/${playlistId}`} addBottomPadding={false}>
        {playlist?.subscribers && <List sx={{ width: '100%' }}>
                {Object.keys(playlist.subscribers).map((subscriber, i) => {
                    return <ListItem key={subscriber} alignItems="flex-start" secondaryAction={playlist.isOwner && subscriber !== currentUser.uid ? <IconButton edge="end" aria-label="block" onClick={() => handleBlock(subscriber)}>
                    {playlist.subscribers[subscriber].status === 'bloqued' ? <NoAccountsIcon style={{fontSize: 32}} color='error' /> : <AdminPanelSettingsIcon style={{fontSize: 32}} color='success' />}
                    </IconButton> : null}>
                        <ListItemAvatar>
                            <Badge badgeContent={i+1} sx={{
                                "& .MuiBadge-badge": {
                                    color: "#333",
                                    backgroundColor: i === 0 ? "#00DE83" : i === 1 ? "#43feb1" : i === 2 ? "#adffdd" : "#FFFFFF"
                                }
                            }}>
                                <Avatar alt={playlist.subscribers[subscriber].display_name} src={playlist.subscribers[subscriber].picture} />
                            </Badge>
                        </ListItemAvatar>
                        <ListItemText primary={playlist.subscribers[subscriber].display_name} secondary={<Typography component="span" variant="body2" sx={{ display: 'block' }} color="text.secondary">
                            <div noWrap>{t('playlist.subscribers.since', {status: t(`playlist.subscribers.${playlist.subscribers[subscriber].status}`), since: moment(playlist.subscribers[subscriber].added.toDate()).format('LLL') })}</div>
                            <small>
                                {t('playlist.player.subscribersStats', { 
                                    tracks: playlist.subscribers[subscriber].tracks ? playlist.subscribers[subscriber].tracks : 0,
                                    votes: playlist.subscribers[subscriber].votes ? playlist.subscribers[subscriber].votes : 0
                                })}
                            </small>
                        </Typography>} />
                    </ListItem>
                })}
        </List>}
    </StandardLayout>
}