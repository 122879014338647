import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { getConfig } from './inc/config.js';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { initializeApp } from "firebase/app";
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import { ReactNotifications } from 'react-notifications-component'
import { CookiesProvider } from 'react-cookie';

import './language/i18n'
import './inc/prototypes.js'

import { GlobalProvider } from './inc/GlobalContext';
import { Analytics } from './inc/Analytics.js';

import Home from './routes/Home';
import Profile from './routes/Profile';
import Playlist from './routes/Playlist';
import PlaylistSettings from './routes/PlaylistSettings';
import PlaylistSubscribers from './routes/PlaylistSubscribers.js';
import Messenger from './routes/Messenger.js';
import Player from './routes/Player';
import Queue from './routes/Queue';
import Account from './routes/Account';
import { Auth, AuthConfirm, AuthProfile, AuthSpotify, AuthRoute } from './routes/Auth';

initializeApp(getConfig().firebaseConfig);

if('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
    console.log('ServiceWorker registration successful with scope: ', registration.scope);
  }).catch((err) => {
    console.log('ServiceWorker registration failed: ', err);
  });
}

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#00DE83',
    },
    secondary: {
      main: '#d5f2e6',
    },
    light: {
      main: '#fff',
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Analytics><AuthRoute><Home /></AuthRoute></Analytics>
  },
  {
    path: "/profile",
    element: <Analytics><AuthRoute><Profile /></AuthRoute></Analytics>
  },
  {
    path: "/account",
    element: <Analytics><AuthRoute><Account /></AuthRoute></Analytics>
  },
  {
    path: "/playlist/:playlistId",
    element: <Analytics><AuthRoute><Playlist /></AuthRoute></Analytics>
  },
  {
    path: "/playlist/:playlistId/subscribers",
    element: <Analytics><AuthRoute><PlaylistSubscribers /></AuthRoute></Analytics>
  },
  {
    path: "/playlist/:playlistId/settings",
    element: <Analytics><AuthRoute><PlaylistSettings /></AuthRoute></Analytics>
  },
  {
    path: "/playlist/:playlistId/messenger",
    element: <Analytics><AuthRoute><Messenger /></AuthRoute></Analytics>
  },
  {
    path: "/playlist/:playlistId/player",
    element: <Analytics><AuthRoute><Player /></AuthRoute></Analytics>
  },
  {
    path: "/playlist/:playlistId/queue",
    element: <Analytics><AuthRoute><Queue /></AuthRoute></Analytics>
  },
  {
    path: "/auth",
    element: <Analytics><Auth /></Analytics>
  },
  {
    path: "/auth/spotify",
    element: <Analytics><AuthSpotify />,</Analytics>
  },
  {
    path: "/auth/confirm",
    element: <Analytics><AuthConfirm /></Analytics>
  },
  {
    path: "/auth/profile",
    element: <Analytics><AuthRoute><AuthProfile /></AuthRoute></Analytics>
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
      <GlobalProvider>
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <ReactNotifications />
          <RouterProvider router={router} />
        </ThemeProvider>
      </GlobalProvider>
    </CookiesProvider>
);

reportWebVitals();
