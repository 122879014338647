import { Container, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loading() {
    return <Container>
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ mb: 1 }}>
                    <CircularProgress />
                </Box>
                <Box>
                    Loading...
                </Box>
            </Box>
        </Box>
    </Container>
}