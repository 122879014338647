import { useState, useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { TextField, InputAdornment, IconButton, Box, Typography, Alert, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';

import { getConfig } from '../inc/config.js';
import { GlobalContext } from '../inc/GlobalContext';
import { getSpotifyCredentials } from '../inc/User.js';
import { createPlaylist } from '../inc/Playlist.js';
import { firestore } from '../inc/config.js';

import { StandardLayout } from '../layouts/standard/StandardLayout';
import { PlaylistItem } from '../components/playlist/PlaylistItem';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';

export default function Home() {
    const { user } = useContext(GlobalContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [ cookies, setCookie ] = useCookies(['playlist-subscribe']); // eslint-disable-line
    const db = firestore();
    const config = getConfig();
    const [ spotifyCredentials, setSpotifyCredentials ] = useState(null);
    const [ displayMode, setDisplayMode ] = useState('buttons');
    const [ playlistName, setPlaylistName ] = useState('');
    const [ loading, setLoading ] = useState(false);
    const [ gLoading, setGLoading ] = useState(false);
    const [ playlists, setPlaylists ] = useState([]);
    const [ subscribedPlaylists, setSubscribedPlaylists ] = useState([]);
    const [ error, setError ] = useState(null);
    const [ tabDisplay, setTabDisplay ] = useState('my-playlists');

    const handlePlaylistCreate = (e) => {
        setLoading(true);
        e.preventDefault();
        createPlaylist(playlistName).then((playlist) => {
            setLoading(false);
            setDisplayMode('buttons');
        }).catch((error) => {
            setLoading(false);
            setError(t('errors.createPlaylist'));
            console.error(error);
        });
    };

    useEffect(() => {
        if(cookies['playlist-subscribe']) {
            const playlistRedirect = cookies['playlist-subscribe'];
            setCookie('playlist-subscribe', '', { path: '/', expires: new Date(0) });
            navigate(`/playlist/${playlistRedirect}`);
        }
        setGLoading(true)
        getSpotifyCredentials().then((credentials) => {
            setSpotifyCredentials(credentials);
        });

        const q = query(collection(db, "playlists"), where("owner", "==", user.uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            setGLoading(false)
            const playlists = [];
            querySnapshot.forEach((doc) => {
                playlists.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setPlaylists(playlists);
        });

        // Listen to the subscribed playlists
        const q2 = query(collection(db, "playlists"), where("subscribers." + user.uid + ".status", "==", 'subscriber'));
        const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
            const playlists = [];
            querySnapshot.forEach((doc) => {
                playlists.push({
                    id: doc.id,
                    ...doc.data()
                });
            });
            setSubscribedPlaylists(playlists);
        });

        return () => {
            unsubscribe();
            unsubscribe2();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event, newAlignment) => {
        console.log(newAlignment)
        if (newAlignment) {
            setTabDisplay(newAlignment)
        }
    };

    return <StandardLayout title={t('home.title')}>
        {displayMode === 'create' ? (
            <form onSubmit={handlePlaylistCreate}>
                <TextField 
                    label={t('home.playlistName')} 
                    fullWidth 
                    required
                    value={playlistName}
                    onChange={(e) => setPlaylistName(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setDisplayMode('buttons')}>
                                    <HighlightOffIcon />
                                </IconButton>
                                <IconButton type="submit">
                                    {loading ? <CircularProgress size={24} /> : <CheckCircleOutlineIcon color="primary" />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </form>
        ) : displayMode === 'connect' ? (
            <Box>
                <Typography variant="body">{t('home.spotifyConnectExplain')}</Typography>
                <Button variant="contained" sx={{ mt: 2 }} fullWidth color="primary" onClick={() => document.location.href = config.spotifyAuthUrl}>{t('home.spotifyConnect')}</Button>
            </Box>
        ) : (
            <Button fullWidth variant="contained" color="primary" onClick={() => setDisplayMode(spotifyCredentials === false ? 'connect' : 'create')}>{t('home.createPlaylist')}</Button>
        )}
        {error && <Alert severity="error">{error}</Alert>}
        {loading && <Box id="loader-wrapper" sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, .5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}><CircularProgress size={72} /></Box>}
        {gLoading && <Box sx={{textAlign: 'center', mt: 2}}><CircularProgress size={72} /></Box>}
        {!gLoading && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2}}>
            <ToggleButtonGroup fullWidth color="primary" exclusive onChange={handleChange} value={tabDisplay}>
                <ToggleButton value="my-playlists">{t('playlist.mine')}</ToggleButton>
                <ToggleButton value="subscribed-playlists">{t('playlist.subscribed')}</ToggleButton>
            </ToggleButtonGroup>
        </Box>}
        {!gLoading && tabDisplay === 'my-playlists' && (
            <Box 
                className="playlists"
                sx={{
                    display: 'flex',
                    gap: '10px',
                    mt: 2,
                    flexWrap: 'wrap'
                }}   
            >
                {playlists.length === 0 && <Typography variant="body">{t('playlist.noPlaylists')}</Typography>}
                {playlists.map((playlist) => (
                    <PlaylistItem key={playlist.id} playlist={playlist} />
                ))}
            </Box>
        )}
        {!gLoading && tabDisplay === 'subscribed-playlists' && (
            <Box
                className="playlists"
                sx={{
                    display: 'flex',
                    gap: '10px',
                    mt: 2,
                    flexWrap: 'wrap'
                }}
            >
                {subscribedPlaylists.length === 0 && <Typography variant="body">{t('playlist.noSubscribed')}</Typography>}
                {subscribedPlaylists.map((playlist) => (
                    <PlaylistItem key={playlist.id} playlist={playlist} />
                ))}
            </Box>
        )}
    </StandardLayout>;
}