import React, { useState, useEffect, useRef, useContext } from 'react';
import { Box, Grid, LinearProgress, Avatar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { GlobalContext } from '../../inc/GlobalContext';
import { SearchTrackItem } from '../playlist/TrackItems.js';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

const avatarSize = 72;

export const MiniStatus = ({ playlist, showPlayerDetails, setShowPlayerDetails }) => {
    const { t } = useTranslation();
    const { setPlaybackState } = useContext(GlobalContext);
    const [playback, setPlayback] = useState(null);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [playedTrack, setPlayedTrack] = useState(null);
    const [recommendations, setRecommendations] = useState([]);
    const interval = useRef(null);

    const style = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100px',
        background: 'rgba(0, 0, 0, 0.9)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        padding: 10
    };

    const updatePlaybackStatus = () => {
        if(playlist && playlist.playback && playlist.playback.is_playing) {
            setDuration(playlist.playback.duration);
            const position = playlist.playback.position;
            const updatedSince = new Date().getTime() - playlist.playback.updated.toDate().getTime();
            if(interval.current) clearInterval(interval.current);
            if(updatedSince < playlist.playback.duration) {
                setPlayback(playlist.playback);
                setProgress(position + updatedSince);
                interval.current = setInterval(() => {
                    setProgress((progress) => progress + 100);
                }, 100);
            } else {
                setPlayback(null);
                setProgress(0);
            }
        } else {
            if(interval.current) clearInterval(interval.current);
            setPlayback(null);
            setProgress(0);
        }
    }

    useEffect(() => {
        setPlayedTrack(playlist && playlist.playedTracks && playlist.playedTracks[playback?.current_track?.id] ? playlist.playedTracks[playback.current_track.id] : null);
        if(playback && playback.recos) setRecommendations(playback.recos);
        // eslint-disable-next-line
    }, [playback]);

    useEffect(() => {
        updatePlaybackStatus();
        // eslint-disable-next-line
    }, [playlist]);

    useEffect(() => {
        // Scroll to top of div when details are shown / hidden
        if(document.getElementById('playedTrack')) {
            document.getElementById('playedTrack').scrollTo(0, 0);
        }
    }, [showPlayerDetails]);

    // Update playback status on resume (web app coming back to foreground)
    useEffect(() => {
        const handleVisibilityChange = () => {
            if(document.hidden) {
                if(interval.current) clearInterval(interval.current);
            } else {
                updatePlaybackStatus();
            }
        };
        document.addEventListener("visibilitychange", handleVisibilityChange, false);
        return () => document.removeEventListener("visibilitychange", handleVisibilityChange);

        // eslint-disable-next-line
    }, [playlist]);

    useEffect(() => {
        if(progress >= duration) {
            setProgress(0);
            setPlayback(null);
        }
    }, [progress, duration]);

    useEffect(() => {
        if(showPlayerDetails === false) setPlaybackState('paused');
        // eslint-disable-next-line
    }, [showPlayerDetails]);
    
    if(playback && playback.current_track && playback.is_playing) {
        return (<>
            <Box id="playedTrack" className={showPlayerDetails ? 'box-visible' : 'box-hidden'} style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(0, 0, 0, 0.9)',
                zIndex: 800,
                padding: 10,
                paddingBottom: 110,
                overflowY: 'auto',
            }}>
                <Grid container style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #ccc', paddingBottom: 10 }}>
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                        {playback.current_track && playback.current_track.album && playback.current_track.album.images && playback.current_track.album.images.length > 0 && (
                            <img alt={playback.current_track.album.name} src={playback.current_track.album && playback.current_track.album.images && playback.current_track.album.images[0] && playback.current_track.album.images[0].url} style={{ width: '80px', height: '80px', boxSizing: 'cover', backgroundPosition: 'center', borderRadius: 10 }} />
                        )}
                    </Grid>
                    <Grid item xs={9}>
                        <h3 style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{playback.current_track && playback.current_track.name}</h3>
                        <h5 style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{playback.current_track && playback.current_track.artists && playback.current_track.artists.map((artist) => artist.name).join(', ')}</h5>
                        <h6 style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{playback.current_track && playback.current_track.album && playback.current_track.album.name}</h6>
                        <Box sx={{ fontSize: '14px' }}>{moment(duration - progress).format('mm:ss')}</Box>
                    </Grid>
                </Grid>
                {Object.keys(playedTrack && playedTrack.votes ? playedTrack.votes : {}).length > 0 && (
                    <Grid container>
                        <Grid item xs={12}>
                            <h3>{t('playlist.track.votes')}</h3>
                            {Object.keys(playedTrack && playedTrack.votes ? playedTrack.votes : {}).map((voter) => {
                                if(!playlist.subscribers[voter]) return null;
                                return <Box key={voter} sx={{ mt: 2 }}>
                                    <Grid container spacing={2} style={{ width: '100%' }}>
                                        <Grid item xs={3}>
                                            {playlist.subscribers[voter] && playlist.subscribers[voter].picture && (
                                                <Avatar sx={{ width: avatarSize, height: avatarSize }} src={playlist.subscribers[voter].picture} alt={playlist.subscribers[voter].display_name} />
                                            )}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography variant="h6" color="text.primary">{playlist.subscribers[voter] ? playlist.subscribers[voter].display_name : playedTrack.votes[voter].display_name}</Typography>
                                            <Typography variant="caption" color="text.secondary">
                                            {t('playlist.track.voted', { date: moment(playedTrack.votes[voter].added.toDate()).format('LL'), time: moment(playedTrack.votes[voter].added.toDate()).format('HH:mm')})}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                            {playedTrack.votes[voter].value > 0 ? (
                                                <ThumbUpIcon />
                                            ) : (
                                                <ThumbDownIcon />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                            })}
                        </Grid>
                    </Grid>
                )}
                {recommendations.length > 0 && (
                    <Box>
                        <h3>{t('playlist.recommendations')}</h3>
                        {recommendations.map((reco) => <Box key={reco.id}>
                            <SearchTrackItem setTrackResults={() => {}} setDisplaySearch={setShowPlayerDetails} track={reco} playlistId={playlist.id} playlist={playlist}/>
                        </Box>)}
                    </Box>
                )}
            </Box>
            <Box style={style} onClick={() => setShowPlayerDetails(!showPlayerDetails)}>
                <LinearProgress variant="determinate" value={progress / duration * 100} style={{ width: '100%', height: 5, position: 'absolute', top: 0, left: 0 }} />
                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                        {playback.current_track && playback.current_track.album && playback.current_track.album.images && playback.current_track.album.images.length > 0 && (
                            <img alt={playback.current_track.album.name} src={playback.current_track.album && playback.current_track.album.images && playback.current_track.album.images[0] && playback.current_track.album.images[0].url} style={{ width: '80px', height: '80px', boxSizing: 'cover', backgroundPosition: 'center', borderRadius: 10 }} />
                        )}
                    </Grid>
                    <Grid item xs={9}>
                        <h3 style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{playback.current_track && playback.current_track.name}</h3>
                        <h5 style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{playback.current_track && playback.current_track.artists && playback.current_track.artists.map((artist) => artist.name).join(', ')}</h5>
                        <h6 style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{playback.current_track && playback.current_track.album && playback.current_track.album.name}</h6>
                        <Box sx={{ fontSize: '14px' }}>{moment(duration - progress).format('mm:ss')}</Box>
                    </Grid>
                </Grid>
            </Box>
        </>);
    } else {
        return null;
    }
};