import * as React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountMenu from './AccountMenu';

import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { ReactComponent as FullLogoSvg } from '../../assets/images/logo/logo-horizontal-dark.svg';
import { ReactComponent as LogoSvg } from '../../assets/images/logo/glyphe-dark.svg';

export default function Header({ title, backButton, fullLogo }) {
    const [displayMenu, setDisplayMenu] = React.useState(false);
    
    const navigate = useNavigate();
    return (
        <Box>
            <AccountMenu setDisplayMenu={setDisplayMenu} displayMenu={displayMenu} />
            <Box style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
                paddingTop: '5px',
            }}>
                {fullLogo ? (
                    <FullLogoSvg style={{ width: 200, height: 60 }} />
                ) : (
                    <Button style={{ width: 65, height: 65 }} onClick={() => navigate('/')}>
                        <LogoSvg style={{ width: 60, height: 60 }} />
                    </Button>
                )}
                <Button style={{ width: 65, height: 65 }} onClick={(e) => setDisplayMenu(true)}>
                    <MenuIcon 
                        style={{ 
                            width: 50, 
                            height: 50, 
                            color: 'white',
                        }}
                    />
                </Button>
            </Box>
            <Box style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontSize: '1.5rem',
                height: '36px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                marginTop: '10px',
            }}>
                {backButton && backButton === true ? 
                    <ArrowBackIosIcon onClick={() => navigate(-1)} />
                : backButton && backButton === 'home' ? <ArrowBackIosIcon onClick={() => navigate('/')} />
                : backButton ? <ArrowBackIosIcon onClick={() => navigate(backButton)} /> : null}
                {title}
            </Box>
        </Box>
    );
}