import axios from "axios";
import { getConfig } from "./config";
import { getAuth } from "firebase/auth";

/** 
 * Communicate with Cloud functions API
 * @param {string} url - API endpoint
 * @param {string} method - HTTP method
 * @param {object} params - Parameters to send
 */
export const transport = (url, method, params, zone) => {
    return new Promise((resolve, reject) => {
        const user = getAuth().currentUser;

        let options = {
            url: getConfig().apiBaseUrlEu + url,
            method: method ? method : "GET",
            data: params ? params : {},
        }

        if((!method || method === "GET") && params) {
            const urlParams = new URLSearchParams(params);
            options.url += "?" + urlParams.toString();
        }
        
        if(user) options.headers = {
            authorization: `Basic ${btoa(user.uid + ":" + user.stsTokenManager.accessToken)}`
        }
        
        axios(options).then((response) => {
            resolve(response.data);
        }).catch((error) => {
            reject(error);
        });
    });
}