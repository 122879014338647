import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

export const getConfig = () => {
    const config = {
        "dev": {
            "apiBaseUrl": "http://127.0.0.1:5001/partyfy-io-app/us-central1/api",
            "apiBaseUrlEu": "http://127.0.0.1:5001/partyfy-io-app/europe-west1/api_europe",
            "spotifyAuthUrl": "http://127.0.0.1:5001/partyfy-io-app/us-central1/api/auth/spotify",
            "firebaseConfig": {
                apiKey: "AIzaSyAyQmYhTK8VMrAc4Us5gV0Xl-sTboiyGxg",
                authDomain: "partyfy-io-app.firebaseapp.com",
                projectId: "partyfy-io-app",
                storageBucket: "partyfy-io-app.appspot.com",
                messagingSenderId: "263088092616",
                appId: "1:263088092616:web:9b8bd4ccb7f7f80572fcfd",
                measurementId: "G-D4T1XNL361"
            }
        },
        "prod": {
            "apiBaseUrl": "https://us-central1-partyfy-io-app.cloudfunctions.net/api",
            "apiBaseUrlEu": "https://europe-west1-partyfy-io-app.cloudfunctions.net/api_europe",
            "spotifyAuthUrl": "https://us-central1-partyfy-io-app.cloudfunctions.net/api/auth/spotify",
            "firebaseConfig": {
                apiKey: "AIzaSyAyQmYhTK8VMrAc4Us5gV0Xl-sTboiyGxg",
                authDomain: "partyfy-io-app.firebaseapp.com",
                projectId: "partyfy-io-app",
                storageBucket: "partyfy-io-app.appspot.com",
                messagingSenderId: "263088092616",
                appId: "1:263088092616:web:9b8bd4ccb7f7f80572fcfd",
                measurementId: "G-D4T1XNL361"
            }
        }
    }
    return document.location.hostname === 'localhost' ? config.dev : config.prod;
}

export const firestore = () => {
    const app = initializeApp(getConfig().firebaseConfig);
    return getFirestore(app);
}

export const storage = () => {
    const app = initializeApp(getConfig().firebaseConfig);
    return getStorage(app);
}