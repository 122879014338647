import { transport } from "../inc/api";

/**
 * Get recommendations for a tracklist
 */
export const getRecommendations = (playlist, track) => {
    return new Promise((resolve, reject) => {
        transport(`/spotify/${playlist}/recommendations${track ? `?track=${track}` : ''}`, "GET").then((res) => {
            if(res.success && res.recommendations) {
                resolve(res.recommendations);
            } else {
                reject(new Error("No recommendations found"));
            }
        }).catch((err) => {
            console.log(err);
            reject(err);
        })
    });
}

/**
 * Play track on playlist
 * @param {*} playlist 
 * @param {*} track 
 * @param {*} device 
 * @returns 
 */
export const playTrack = (playlist, track, device) => {
    return new Promise((resolve, reject) => {
        transport(`/spotify/${playlist}/play/${device}`, "POST", { track}).then((res) => {
            if(res.success) {
                resolve(res);
            } else {
                reject(new Error("Could not play track"));
            }
        }).catch((err) => {
            console.log(err);
            reject(err);
        })
    });
}

/**
 * Add track to queue
 * @param {*} playlist 
 * @param {*} track 
 * @param {*} device 
 * @returns 
 */
export const queueTrack = (playlist, track) => {
    return new Promise((resolve, reject) => {
        transport(`/spotify/${playlist}/queue`, "POST", { track }, 'eu').then((res) => {
            if(res.success) {
                resolve(res);
            } else {
                reject(new Error("Could not queue track"));
            }
        }).catch((err) => {
            console.log(err);
            reject(err);
        })
    });
}

/**
 * Get playback state
 * @param {*} playlist
 * @returns 
 */
export const getPlaybackState = (playlist) => {
    return new Promise((resolve, reject) => {
        transport(`/spotify/${playlist}/playback`, "GET", null, 'eu').then((res) => {
            if(res.success && res.playback) {
                resolve(res.playback);
            } else {
                reject(new Error("No playback found"));
            }
        }).catch((err) => {
            console.log(err);
            reject(err);
        })
    });
}