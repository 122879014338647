// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'capitalize', {
    value: function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    },
    enumerable: false
});

/**
 * Convert string to number
 */
// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'stringToNumber', {
    value: function() {
        let hash = 0;
        for (let i = 0; i < this.length; i++) {
            hash = (hash << 5) - hash + this.charCodeAt(i);
            hash &= hash; // Convert to 32-bit integer
        }
        return Math.abs(hash);
    },
    enumerable: false
});