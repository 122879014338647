import { Container, Box } from '@mui/material';
import Header from './Header';

export const StandardLayout = ({ title, children, backButton, addBottomPadding, fullLogo }) => {
    return (
        <Container>
            <Header title={title} backButton={backButton ? backButton : false} fullLogo={fullLogo} /> 
            <Box style={ 
                addBottomPadding ? {
                    paddingBottom: 100
                } : {}
             }>{children}</Box>
            {/* <Footer /> @todo header & footer  */}
        </Container>
    );
}