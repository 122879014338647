import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

import en from './langs/en.json';
import fr from './langs/fr.json';

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources: {
            en: en,
            fr: fr
        },
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });