import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Box, TextField, Button, Alert, Typography } from '@mui/material';
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useTranslation } from 'react-i18next';

import { firestore } from '../inc/config.js';
import { GlobalContext } from '../inc/GlobalContext';

import { StandardLayout } from '../layouts/standard/StandardLayout';
import Loading from '../layouts/standard/Loading.js';

import { CircularProgress } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';

/** 
 * Profile configuration screen after authentification
 */
export const AuthProfile = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if(!auth.currentUser) navigate('/auth');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <StandardLayout title={t('auth.profile')}>
        <Box sx={{display: 'flex', flexDirection: 'column', mt:5}}>
            Profile
        </Box>
    </StandardLayout>;
}

/**
 * Receivespotify credentials from Spotify API & store them to user profile (for the moment)
 */
export const AuthSpotify = () => {
    const { t } = useTranslation();
    const auth = getAuth();
    const navigate = useNavigate();
    const db = firestore();
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    auth.onAuthStateChanged((user) => {
        const url = new URL(window.location.href);

        if(url.searchParams.get('access_token')) {
            const docRef = doc(db, "credentials", auth.currentUser.uid);
            setDoc(docRef, {
                type: 'spotify',
                access_token: url.searchParams.get('access_token'),
                refresh_token: url.searchParams.get('refresh_token'),
                expires_in: new Date(new Date().getTime() + (url.searchParams.get('expires_in').replace(/[^0-9]+/, '') * 1000))
            }).then(() => {
                console.log("Document successfully written!");
                navigate('/');
            }).catch((error) => {
                setError(error);
                setLoading(false);
                console.error("Error writing document: ", error);
            });
        }
    });

    return <StandardLayout title="Spotify">
        <Box sx={{display: 'flex', flexDirection: 'column', mt:5}}>
            {loading && <CircularProgress />}
            {error && <Alert severity="error">{t('spotify.login_error')}</Alert>}
        </Box>
    </StandardLayout>;

}

/**
 * Confirm email registration from Google Firebase
 */
export const AuthConfirm = () => {
    const navigate = useNavigate();
    const auth = getAuth();
    const { t } = useTranslation();
    const [ error, setError ] = useState(false);

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            let tmpEmail = window.localStorage.getItem('emailForSignIn');
            if (!tmpEmail) {
                tmpEmail = window.prompt('Please provide your email for confirmation');
            }
            signInWithEmailLink(auth, tmpEmail, window.location.href)
                .then((result) => {
                    window.localStorage.removeItem('emailForSignIn');
                    // Wait for user to be logged in before redirecting to home
                    setTimeout(() => {
                        navigate('/')
                    }, 1000);
                })
                .catch((error) => {
                    console.log(error)
                    setError(true);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <StandardLayout title={t('auth.title')}>
        <Box sx={{display: 'flex', flexDirection: 'column', mt:5}}>
            {error && <Alert severity="error">{t('auth.confirm.error')}</Alert>}
        </Box>
    </StandardLayout>;
}

export const getCurrentUser = () => {
    const auth = getAuth();
    if(localStorage.getItem('authUser')) {
        return JSON.parse(localStorage.getItem('authUser'));
    } else {
        auth.onAuthStateChanged((user) => {
            if(user) {
                localStorage.setItem('authUser', JSON.stringify(user));
                return user;
            } else {
                return false;
            }
        });
    }
}

/**
 * Authentification screen
 */
export const Auth = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [displayAuthByMail, setDisplayAuthByMail] = useState(false);

    useEffect(() => {
        // Check if user is already logged in
        if(auth.currentUser) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const actionCodeSettings = {
        url: `${new URL(document.location.href).origin}/auth/confirm`,
        handleCodeInApp: true
    };

    const sendSignInEmail = (e) => {
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
            window.localStorage.setItem('emailForSignIn', email);
            setSuccess(true);
        })
        .catch((error) => {
            console.log(error)
            setError(true);
        });
    }

    const signInWithGooglePopup = () => {
        signInWithPopup(auth, provider).then((result) => {
            document.location.href = '/';
        }).catch((error) => {
            console.log(error)
            setError(true);
        })
    }

    return <StandardLayout fullLogo={true}>
        <Box sx={{display: 'flex', flexDirection: 'column', height: 'calc(100vh - 150px)', justifyContent: 'center'}}>
            <Typography variant="h5" style={{
                marginBottom: '2em',
                textAlign: 'center',
                textTransform: 'uppercase',
            }}>{t('auth.title')}</Typography>
            {success && <Box sx={{mb: 1}}>
                <Alert severity="success">{t('auth.success')}</Alert>
            </Box>}
            {error && <Box sx={{mb: 1}}>
                <Alert severity="error">{t('auth.error')}</Alert>
            </Box>}
            {displayAuthByMail ? <Box>
                <Box sx={{mb: 1}}>{t('auth.explain')}</Box>
                <Box>
                    <TextField name="email" type="email" label={t('auth.email')} onChange={(e) => setEmail(e.target.value)} variant="filled" fullWidth />
                </Box>
                <Box sx={{mt: 1}}>
                    <Button variant="contained" onClick={sendSignInEmail} fullWidth style={{ color: '#fff' }}>{t('auth.submit')}</Button>
                </Box>
            </Box> : <Box>
                <Button fullWidth variant="contained" onClick={() => setDisplayAuthByMail(true)} style={{
                    color: '#fff'
                }}>
                    <EmailIcon style={{marginRight: '10px'}} />
                    {t('auth.emailConnect')}
                </Button>
            </Box>}
            <Box sx={{mt: 4}}>
                <Button variant="contained" onClick={signInWithGooglePopup} style={{
                    backgroundColor: 'rgba(200, 200, 200, 0.3)',
                    color: '#fff'
                }} fullWidth>
                    <GoogleIcon style={{marginRight: '10px'}} />
                    {t('auth.google')}
                </Button>
            </Box>
        </Box>
    </StandardLayout>;
}

export const AuthRoute = ({children}) => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['playlist-subscribe']); // eslint-disable-line no-unused-vars
    const { user } = useContext(GlobalContext);
    const { loading } = useContext(GlobalContext);

    useEffect(() => {
        if(user === null && !loading) {
            // Get playlist ID & store it in cookie
            // http://localhost:3000/playlist/inoYp8vD0dRi2f0HAMPR
            const playlistId = document.location.href.match(/playlist\/([a-zA-Z0-9]+)/);
            if(playlistId && playlistId[1]) {
                setCookie('playlist-subscribe', playlistId[1], {
                    path: '/',
                    maxAge: 3600
                })
            }
            navigate('/auth');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, loading]);
    
    return loading ? <Loading /> : user === null ? null : children;
}