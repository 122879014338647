import { useEffect, useState, useRef } from 'react';

// Third party
import { useTranslation, Trans } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, onSnapshot } from "firebase/firestore";
import { Typography, Grid, Button, Box, Dialog, Badge, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, IconButton, InputAdornment, Switch } from '@mui/material';
import { ref, uploadBytes } from "firebase/storage";
import Resizer from "react-image-file-resizer";
import { getMessaging, getToken } from "firebase/messaging";

// Methods & functions
import { reorderTracks, removePlaylist, importTracksFromSpotify, updatePlaylist } from '../inc/Playlist';
import { getCurrentUser, subscribeToPlaylistMessages, unsubscribeToPlaylistMessages } from '../inc/User';
import { storage, firestore } from '../inc/config.js';

// Components
import { StandardLayout } from '../layouts/standard/StandardLayout';

// Assets
import CircularProgress from '@mui/material/CircularProgress';
import UploadIcon from '@mui/icons-material/Upload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function PlaylistSettings() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { playlistId } = useParams();
    const [ playlist, setPlaylist ] = useState(null);
    const [ confirmDelete, setConfirmDelete ] = useState(false);
    const [ syncQuestion, setSyncQuestion ] = useState(false);
    const [ syncUrl, setSyncUrl ] = useState("");
    const [ playlistName, setPlaylistName ] = useState("");
    const [ syncLoading, setSyncLoading ] = useState(false);
    const [ nameLoading, setNameLoading ] = useState(false);
    const [ confirmSync, setConfirmSync ] = useState(false);
    const [ userConfig, setUserConfig ] = useState(null);
    const [ user, setUser ] = useState(null);
    const [ syncError, setSyncError ] = useState(false);
    const [ userPlaylistStatus, setUserPlaylistStatus ] = useState(null);
    const [ pictureLoading, setPictureLoading ] = useState(false);
    const [ notificationLoading, setNotificationLoading ] = useState(false);
    const fileInput = useRef(null);
    const db = firestore();
    const messaging = getMessaging();

    const handlePictureChange = (e) => {
        setPictureLoading(true);
        const file = e.target.files[0];
        if(file.type === 'image/jpeg' || file.type === 'image/jpg') {
            const storageRef = ref(storage(), `playlists/${playlistId}/cover.jpg`);
            // Resize image to 500x500
            Resizer.imageFileResizer(file, 500, 500, 'JPEG', 100, 0, (uri) => {
                const resizedFile = new File([uri], 'cover.jpg', { type: 'image/jpeg' });

                uploadBytes(storageRef, resizedFile).then(async (snapshot) => {
                    const url = `https://storage.googleapis.com/${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`
                    await updatePlaylist(playlistId, { image: url });
                    setPictureLoading(false);
                }).catch((error) => {
                    console.log(error);
                    setPictureLoading(false);
                    alert(t('playlist.settings.pictureError'))
                });
            }, 'file');
        } else {
            setPictureLoading(false);
            alert(t('playlist.settings.pictureError'))
        }
    }

    useEffect(() => {
        getCurrentUser().then((user) => {
            setUser(user);
        });
    }, []);
    
    useEffect(() => {
        if(user) {
            try {
                const unsub = onSnapshot(doc(db, "playlists", playlistId), async (doc) => {
                    let playlistData = doc.data();
                    playlistData.id = doc.id;
                    const reorderedTracks = await reorderTracks(playlistData.tracks);
                    playlistData.tracks = reorderedTracks;
                    setPlaylist(playlistData);
    
                    if(!playlistName) setPlaylistName(playlistData.name);
        
                    let userPlaylistStatusSet = false;
                    Object.keys(playlistData.subscribers).map((subscriber) => {
                        if(subscriber === user.uid) {
                            userPlaylistStatusSet = true;
                            setUserPlaylistStatus(playlistData.subscribers[subscriber].status);
                        }
                        return subscriber;
                    });
                    if(!userPlaylistStatusSet) {
                        setUserPlaylistStatus('none');
                    }
                }, async (error) => {
                    console.log("Error getting document:", error);
                    if (error.code === 'permission-denied') {
                        // Handle permission denied error
                    }
                });
    
                const unsubUser = onSnapshot(doc(db, "users", user.uid), async (doc) => {
                    if(doc.exists()) setUserConfig(doc.data());
                });
        
                return () => {
                    unsub();
                    unsubUser();
                }
            } catch(e) {
                console.log(e)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const handleSyncPlaylist = () => {
        // Sync playlist
        setSyncLoading(true);
        let spotifyPlaylistId = syncUrl.split('?').shift();
        spotifyPlaylistId = spotifyPlaylistId.split('/').pop();
        spotifyPlaylistId = spotifyPlaylistId.split(':').pop();

        importTracksFromSpotify(playlistId, spotifyPlaylistId).then(() => {
            // Alert message
            setSyncLoading(false);
            setConfirmSync(true);
        }).catch((error) => {
            // Alert message
            setSyncLoading(false);
            setSyncError(true);
        });
    }

    const manageSubscription = (status) => {
        setNotificationLoading(true);
        getToken(messaging, {vapidKey: "BBtLyRcCJWFTikz8qQEv1cHn7KJTlJ5mO9LsXQFqHzmAHsTP6RaKlt6A304ueVTYd2pqTBTuiU5RXbzFgPIDWI8"}).then((currentToken) => {
            if(status) {
                subscribeToPlaylistMessages(playlistId, currentToken).then(() => {
                    setNotificationLoading(false);
                }).catch((error) => {
                    console.log(error)
                    setNotificationLoading(false);
                });
            } else {
                unsubscribeToPlaylistMessages(playlistId, currentToken).then(() => {
                    setNotificationLoading(false);
                }).catch((error) => {
                    console.log(error)
                    setNotificationLoading(false);
                });
            }
        });
    }


    return <StandardLayout title={playlist && playlist.name && t('playlist.settings.title', { name: playlist?.name })} backButton={`/playlist/${playlistId}`} addBottomPadding={playlist?.playback && playlist?.playback.is_playing}>
        <Grid container sx={{ marginBottom: 1 }}>
            <Grid item xs={2}>
                <Switch checked={userConfig && userConfig.topics && userConfig.topics[playlistId] ? userConfig.topics[playlistId] : false} onChange={(e) => manageSubscription(e.target.checked)} />
            </Grid>
            <Grid item xs={10}>
                {t('playlist.settings.notificationsText')}
                {notificationLoading && <CircularProgress size={12} style={{ marginLeft: 4 }} />}
            </Grid>
        </Grid>
        {userPlaylistStatus === 'owner' && <>
            <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)}>
                <DialogTitle>{t('playlist.settings.deleteConfirmationTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText><Trans i18nKey="playlist.settings.deleteConfirmationText" values={{ playlist: playlist?.name }} /></DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDelete(false)} color="primary">{t('common.cancel')}</Button>
                    <Button onClick={async () => {
                        // Delete playlist
                        removePlaylist(playlistId);
                        navigate('/');
                        setConfirmDelete(false);
                    }} color="error">{t('playlist.settings.delete')}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={syncQuestion} onClose={() => setSyncQuestion(false)}>
                <DialogTitle>{t('playlist.settings.syncQuestionTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText><Trans i18nKey="playlist.settings.syncQuestionText" values={{ playlist: playlist?.name }} /></DialogContentText>
                    <Box sx={{ position: 'relative' }}>
                        <TextField sx={{ mt: 2 }} onChange={(e) => setSyncUrl(e.target.value)} value={syncUrl} fullWidth placeholder='https://open.spotify.com/playlist/...' />
                        {syncLoading && <CircularProgress size={24} sx={{ position: "absolute", top: 32, right: 15 }} />}
                    </Box>
                    <Button variant="contained" color="primary" onClick={handleSyncPlaylist} sx={{ mt: 2 }} fullWidth>
                        {t('playlist.settings.syncButton')}
                    </Button>
                    <Button variant="outlined" onClick={() => setSyncQuestion(false)} sx={{ mt: 2 }} fullWidth>
                        {t('common.cancel')}
                    </Button>
                </DialogContent>
            </Dialog>
            <Dialog open={confirmSync} onClose={() => setConfirmSync(false)}>
                <DialogTitle>{t('playlist.settings.syncConfirmTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText><Trans i18nKey="playlist.settings.syncConfirmText" values={{ playlist: playlist?.name }} /></DialogContentText>
                    <Button variant="outlined" onClick={() => {
                        setConfirmSync(false);
                        setSyncQuestion(false);
                    }} sx={{ mt: 2 }} fullWidth>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
            <Dialog open={syncError} onClose={() => setSyncError(false)}>
                <DialogTitle>{t('playlist.settings.syncErrorTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText><Trans i18nKey="playlist.settings.syncErrorText" values={{ playlist: playlist?.name }} /></DialogContentText>
                    <Button variant="outlined" color="error" onClick={() => {
                        setSyncError(false);
                    }} sx={{ mt: 2 }} fullWidth>
                        OK
                    </Button>
                </DialogContent>
            </Dialog>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                    <input type="file" onChange={handlePictureChange} ref={fileInput} style={{display: 'none'}} />
                    <Badge
                        overlap="circular"
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent={
                            <IconButton className="ProfilePictureButton" onClick={() => fileInput.current.click()} sx={{bgcolor: "#00DE83", border: "4px solid #121212"}}>
                                {pictureLoading ? <CircularProgress size={24} style={{color: "#FFF"}} /> : <UploadIcon />}
                            </IconButton>
                        }
                    >
                        <img alt={playlist.name} src={`${playlist.image}?${new Date().getTime()}`} style={{ maxWidth: '100%', width: '100%', mt: 2 }} />
                    </Badge>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Grid item xs={12}>
                            {/* <Typography variant="body1">{t('playlist.settings.name')}</Typography> */}
                            {/* <TextField label={t('playlist.settings.name')} fullWidth value={playlist?.name} onChange={(e) => updatePlaylist(playlistId, { name: e.target.value })} /> */}
                            <form onSubmit={async (e) => {
                                setNameLoading(true);
                                e.preventDefault();
                                if(playlist?.name.length === 0) {
                                    setNameLoading(false);
                                    return;
                                } else {
                                    await updatePlaylist(playlistId, { name: playlistName })
                                    setNameLoading(false);
                                }
                            }}>
                                <TextField 
                                    label={t('home.playlistName')} 
                                    fullWidth 
                                    required
                                    value={playlistName}
                                    onChange={(e) => setPlaylistName(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setPlaylistName("")}>
                                                    <HighlightOffIcon />
                                                </IconButton>
                                                <IconButton type="submit">
                                                    {nameLoading ? <CircularProgress size={24} /> : <CheckCircleOutlineIcon color="primary" />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <Button variant="contained" color="secondary" fullWidth onClick={() => setSyncQuestion(true)}>
                                {t('playlist.settings.sync')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">{t('playlist.settings.syncexplain')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '2em' }}>
                        <Grid item xs={12}>
                            <Button onClick={() => setConfirmDelete(true)} variant="contained" color="error" fullWidth>{t('playlist.settings.delete')}</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>}
    </StandardLayout>
}