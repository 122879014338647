import React, { useState, useContext, useEffect, useRef } from 'react';
import { Box, TextField, InputAdornment, IconButton, Avatar, Badge, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ref, uploadBytes } from "firebase/storage";
import { getAuth, updateProfile } from "firebase/auth";
import Resizer from "react-image-file-resizer";

import { storage } from '../inc/config.js';
import { updatePlaylistUserInfos } from '../inc/User';

// Layouts
import { StandardLayout } from '../layouts/standard/StandardLayout';
import { GlobalContext } from '../inc/GlobalContext';

// Assets
import DefaultProfile from '../assets/images/default-user.jpg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UploadIcon from '@mui/icons-material/Upload';

export default function Profile() {
    const { t, i18n } = useTranslation();
    const { user } = useContext(GlobalContext);
    const [ nickname, setNickname ] = useState(user.displayName);
    const [ loading, setLoading ] = useState(false);
    const [ profileLoading, setProfileLoading ] = useState(false);
    const [ profilePicture, setProfilePicture ] = useState(null);
    const fileInput = useRef(null);

    useEffect(() => {
        setProfilePicture(user.photoURL ? user.photoURL : DefaultProfile);
    }, [user]);

    const handleFileChange = (e) => {
        const auth = getAuth();
        setProfileLoading(true);
        const file = e.target.files[0];
        if(file.type === 'image/jpeg' || file.type === 'image/jpg') {
            const storageRef = ref(storage(), `profiles/${user.uid}/profile.jpg`);
            // Resize image to 500x500
            Resizer.imageFileResizer(file, 500, 500, 'JPEG', 100, 0, (uri) => {
                const resizedFile = new File([uri], 'profile.jpg', { type: 'image/jpeg' });
                
                uploadBytes(storageRef, resizedFile).then(async (snapshot) => {
                    const url = `https://storage.googleapis.com/${snapshot.metadata.bucket}/${snapshot.metadata.fullPath}`
                    await updateProfile(auth.currentUser, { photoURL: url });
                    setProfilePicture(url);
                    await updatePlaylistUserInfos();
                    setProfileLoading(false);
                });
            }, 'file');
        } else {
            alert(t('profile.invalidFile'))
            setProfileLoading(false);
        }
    }

    const handleNicknameChange = async () => {
        const auth = getAuth();
        setLoading(true);
        await updateProfile(auth.currentUser, { displayName: nickname });
        await updatePlaylistUserInfos();
        setLoading(false);
    }

    return <StandardLayout title={t('profile.title')}>
        <Box sx={{display: 'flex', flexDirection: 'column', mt:2, alignItems: 'center'}}>
            <Box sx={{mb: 4}}>
                <input type="file" onChange={handleFileChange} ref={fileInput} style={{display: 'none'}} />
                <Badge
                    overlap="circular"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    badgeContent={
                        <IconButton className="ProfilePictureButton" onClick={() => fileInput.current.click()} sx={{bgcolor: "#00DE83", border: "4px solid #121212"}}>
                            {profileLoading ? <CircularProgress size={24} style={{color: "#FFF"}} /> : <UploadIcon />}
                        </IconButton>
                    }
                >
                    {profilePicture ? (
                        <Avatar src={`${profilePicture}?${new Date().getTime()}`} sx={{width: 200, height: 200, mt: 2}} />
                    ) : (
                        <Avatar sx={{width: 200, height: 200, mt: 2, bgcolor: "#00DE83", fontSize: 80}}>
                            {user.displayName.toUpperCase().charAt(0)}
                        </Avatar>
                    )}
                </Badge>
            </Box>
            <TextField 
                label={t('profile.nickname')} 
                fullWidth 
                autoComplete='off'
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {nickname && nickname.length > 0 && <IconButton onClick={(e) => { 
                                setNickname(''); 
                            }}>
                                <HighlightOffIcon />
                            </IconButton>}
                            <IconButton type="submit" onClick={handleNicknameChange}>
                                {loading ? <CircularProgress size={24} /> : <CheckCircleOutlineIcon color="primary" />}
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />
            <TextField 
                select 
                label={t('profile.language')} 
                fullWidth 
                value={i18n.language.split('-')[0]} 
                onChange={(e) => i18n.changeLanguage(e.target.value)}
                sx={{mt: 2}}
            >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="fr">Français</MenuItem>
            </TextField>
        </Box>
    </StandardLayout>;
}