import { useEffect, useState, useRef } from 'react';
import { onSnapshot, doc, updateDoc, setDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Components
import { StandardLayout } from '../layouts/standard/StandardLayout';
import { Box, TextField, InputAdornment, IconButton, Grid, Avatar } from '@mui/material';

// Functions
import { reorderTracks } from '../inc/Playlist';
import { getCurrentUser } from '../inc/User';
import { firestore } from '../inc/config';

// Assets
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';

export default function Messenger() {
    const { t } = useTranslation();
    const { playlistId } = useParams();
    const [playlist, setPlaylist] = useState({});
    const [messenger, setMessenger] = useState({});
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const db = firestore();
    const endMessagesRef = useRef(null);
    const userScrolled = useRef(false);

    const handleSendMessage = async (e) => {
        e.preventDefault();
        userScrolled.current = false;
        setLoading(true);
        const messageData = {
            user: {
                id: user.uid,
                display_name: user.displayName,
                picture: user.photoURL
            },
            message: message,
            date: new Date()
        }

        // Clone messenger variable without using JSON.parse / JSON.stringify
        let tmpMessenger = {...messenger};

        if(!tmpMessenger) tmpMessenger = {};
        if(!tmpMessenger?.messages) tmpMessenger.messages = [];
        tmpMessenger.messages.push(messageData);

        // if(tmpMessenger.messages.length > 100) {
        //     tmpMessenger.messages.shift();
        // }

        try {
            // Create doc if not exists
            if(!messenger.created) {
                await setDoc(doc(db, "messenger", playlistId), {
                    messages: tmpMessenger.messages,
                    created: new Date(),
                    lastMessage: new Date(),
                    owner: playlist.owner
                });
            } else {
                await updateDoc(doc(db, "messenger", playlistId), {
                    messages: tmpMessenger.messages,
                    lastMessage: new Date()
                });
            }
            await updateDoc(doc(db, "playlists", playlistId), {
                lastMessage: new Date(),
                [`subscribers.${user.uid}.lastMessageView`]: new Date()
            });
            setMessage("");
        } catch(e) {
            console.log(e);
        }

        setLoading(false);
    }

    const updateUserInfos = async () => {
        let userData = await getCurrentUser();
        if(playlist && playlist.subscribers && playlist.subscribers[userData.uid]) {
            userData.displayName = playlist.subscribers[userData.uid].display_name;
            userData.photoURL = playlist.subscribers[userData.uid].picture;
        }
        setUser(userData);
    }

    const scrollToBottom = (smooth) => {
        endMessagesRef.current?.scrollIntoView();
    }

    useEffect(() => {
        setUserLastView();
        if(userScrolled.current) return;
        scrollToBottom();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [messenger]);

    useEffect(() => {
        try {
            updateUserInfos();
            const unsub = onSnapshot(doc(db, "playlists", playlistId), async (doc) => {
                let playlistData = doc.data();
                playlistData.id = doc.id;
                const reorderedTracks = await reorderTracks(playlistData.tracks);
                playlistData.tracks = reorderedTracks;
                setPlaylist(playlistData);
            }, async (error) => {
                console.log("Error getting document:", error);
                if (error.code === 'permission-denied') {
                    // Handle permission denied error
                }
            });

            const unsubMessenger = onSnapshot(doc(db, "messenger", playlistId), async (doc) => {
                if(doc.exists()) {
                    let messengerData = doc.data();
                    // if(messengerData.messages) messengerData.messages.sort((a, b) => b.date - a.date);
                    messengerData.id = doc.id;
                    // Replace each messengerData.messages date by a Date object
                    if(messengerData.messages) {
                        messengerData.messages = messengerData.messages.map((message) => {
                            message.date = message.date.toDate();
                            return message;
                        });
                    }
                    setMessenger(messengerData);
                    if(user) {
                        setUserLastView();
                    }
                } else {
                    setMessenger({
                        messages: []
                    });
                }
            }, async (error) => {
                console.log("Error getting document:", error);
                if (error.code === 'permission-denied') {
                    // Handle permission denied error
                }
            });
    
            return () => {
                unsub();
                unsubMessenger();
            }
        } catch(e) {
            console.log(e)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setUserLastView = async () => {
        if(playlist && playlist.subscribers && playlist.subscribers[user.uid]) {
            await updateDoc(doc(db, "playlists", playlistId), {
                [`subscribers.${user.uid}.lastMessageView`]: new Date()
            });
        }
    }
        

    // Detect scroll on messages container && set userScrolled to true if user is not at the bottom
    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if(!bottom) {
            userScrolled.current = true;
        } else {
            userScrolled.current = false;
        }
    }

    return (
        <StandardLayout title={playlist && playlist.name && t('playlist.messenger.title', { name: playlist?.name })} backButton={`/playlist/${playlistId}`} addBottomPadding={false}>
            <Box id="messages-container" sx={{
                paddingBottom: '100px',
                overflowY: 'auto',
                overflowX: 'hidden',
                height: 'calc(100vh - 140px)',
            }} onScroll={handleScroll}>
                <Box sx={{
                    padding: 2,
                    display: 'grid',
                    gap: 2,
                }}>
                    {messenger?.messages && messenger.messages.slice(messenger.messages.length - 100, messenger.messages.length).map((message, index) => {
                        if(user && user.uid && user.uid === message.user.id) {
                            return <Box key={index} sx={{
                                width: '80%',
                                maxWidth: '400px',
                                justifySelf: 'flex-end',
                                padding: 2,
                                backgroundColor: '#333',
                                borderRadius: 2,
                                borderTopRightRadius: 0,
                                position: 'relative',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    right: '-19.5px',
                                    width: 0,
                                    height: 0,
                                    border: '10px solid transparent',
                                    borderLeftColor: '#333',
                                    borderTopColor: 'transparent',
                                    borderBottomColor: 'transparent',
                                }
                            }}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={9} style={{ textAlign: 'right' }}>
                                        <Box><span style={{ fontWeight: 'bold' }}>{playlist && playlist.subscribers && playlist.subscribers[message.user.id] ? playlist.subscribers[message.user.id].display_name : message.user.display_name}</span></Box>
                                        <Box>{message.message}</Box>
                                        <Box><span style={{ color: '#999',fontSize: '.7rem' }}>{moment(message.date).fromNow()}</span></Box>
                                    </Grid>
                                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <Avatar style={{
                                            width: 50,
                                            height: 50
                                        }} src={playlist && playlist.subscribers && playlist.subscribers[message.user.id] ? playlist.subscribers[message.user.id].picture : message.user.picture} />
                                    </Grid>
                                </Grid>
                            </Box>
                        } else {
                            return <Box key={index} sx={{
                                width: '80%',
                                maxWidth: '400px',
                                padding: 2,
                                backgroundColor: '#333',
                                borderRadius: 2,
                                borderTopLeftRadius: 0,
                                position: 'relative',
                                '&::after': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: '-19.5px',
                                    width: 0,
                                    height: 0,
                                    // arrow oriented to the left
                                    border: '10px solid transparent',
                                    transform: 'rotate(180deg)',
                                    borderLeftColor: '#333',
                                    borderTopColor: 'transparent',
                                    borderBottomColor: 'transparent',
                                }
                            }}>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center'}}>
                                        <Avatar style={{
                                            width: 50,
                                            height: 50
                                        }} src={playlist && playlist.subscribers && playlist.subscribers[message.user.id] ? playlist.subscribers[message.user.id].picture : message.user.picture} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Box><span style={{ fontWeight: 'bold' }}>{playlist && playlist.subscribers && playlist.subscribers[message.user.id] ? playlist.subscribers[message.user.id].display_name : message.user.display_name}</span></Box>
                                        <Box>{message.message}</Box>
                                        <Box><span style={{ color: '#999',fontSize: '.7rem' }}>{moment(message.date).fromNow()}</span></Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        }
                    })}
                </Box>
                <div ref={endMessagesRef}></div>
            </Box>

            <Box sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                padding: 2,
                paddingTop: 0,
                backgroundColor: '#121212',
            }}>
                <form onSubmit={handleSendMessage}>
                    <TextField
                        id="message"
                        label={t('playlist.messenger.message')}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {message && message.length > 0 && (
                                        <IconButton onClick={() => setMessage("")}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    )}
                                    <IconButton type="submit">
                                        {loading ? <CircularProgress size={24} /> : <SendIcon color="primary" />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </form>
            </Box>
        </StandardLayout>
    );
}