import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, onSnapshot, updateDoc } from "firebase/firestore";
import { firestore } from '../inc/config';
import QRCode from "react-qr-code";
import moment from 'moment';

// Components
import { Box, IconButton, Grid, CardMedia, CardContent, Card, LinearProgress, Typography, Badge, Avatar, Button } from '@mui/material';
import { StandardLayout } from '../layouts/standard/StandardLayout';

// Functions
import { getCurrentUser } from '../inc/User';
import { updatePlaylist, reorderTracks, removeTrackFromPlaylist } from '../inc/Playlist';
import { getPlaybackState, queueTrack, getRecommendations } from '../inc/Spotify';

// Assets
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ScreenLockPortraitIcon from '@mui/icons-material/ScreenLockPortrait';
import { ReactComponent as SpotifyLogo } from '../assets/images/spotify-logo.svg';
import { ReactComponent as SpotifyGlyphe  } from '../assets/images/spotify-glyphe.svg';

export default function Queue() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const db = firestore();
    const { playlistId } = useParams();
    const [ user, setUser] = useState(null);
    const [ playlist, setPlaylist ] = useState(null);
    // const [ loading, setLoading ] = useState(false);
    const [ userPlaylistStatus, setUserPlaylistStatus ] = useState(null);
    const [ wakeLockStatus, setWakeLockStatus ] = useState(false);
    const [ playbackState, setPlaybackState ] = useState(null);
    const [ managed, setManaged ] = useState('stop');
    const [ progress, setProgress ] = useState(0);
    const [ queuedTrackInfos, setQueuedTrackInfos ] = useState(null);
    
    const checkPlayerTimer = useRef(null);
    const forceUpdateTimer = useRef(null);
    const nexTrackTimer = useRef(false);
    const progressTimer = useRef(false);

    const recoTrack = useRef(null);
    const currentTrack = useRef(null);
    const recoList = useRef(null);
    const nextTrack = useRef(null);
    const nexTrackFired = useRef(false);
    const progressRef = useRef(0);

    const updatePlayback = () => {
        console.log("from function", managed)
        if(managed === 'play') {
            // setLoading(true);
            // Get playback status
            getPlaybackState(playlistId).then(async (playback) => {
                // setLoading(false);
                if(playback.is_playing) {
                    setPlaybackState(playback); // Update playback state

                    // Check if we need to get recommendations
                    if(playback.item.id && playback.item.id !== recoTrack.current) {
                        setQueuedTrackInfos(null);
                        nexTrackFired.current = false;
                        recoTrack.current = playback.item.id;
                        recoList.current = await getRecommendations(playlistId, playback.item.id)
                    }

                    // If there is more than 1s difference between progress and playback, update progress
                    if(playback.progress_ms - progressRef.current > 1500 || playback.progress_ms - progressRef.current < -1500 || playback.item.id !== currentTrack.current) {
                        console.log(new Date(), 'Update playback state');
                        currentTrack.current = playback.item.id;
                        // Update playback state
                        updateDoc(doc(db, "playlists", playlistId), {
                            playback: {
                                is_playing: playback.is_playing,
                                current_track: playback.item,
                                position: playback.progress_ms,
                                duration: playback.item ? playback.item.duration_ms : 0,
                                recos: recoList.current,
                                updated: new Date()
                            }
                        });   
                    }

                    // Set force update timer to end of track
                    if(forceUpdateTimer.current) clearTimeout(forceUpdateTimer.current);
                    forceUpdateTimer.current = setTimeout(() => {
                        // console.log('Force update triggered')
                        updatePlayback();
                    }, (playback.item.duration_ms - playback.progress_ms));

                    // Set next track timer
                    if(nexTrackTimer.current) clearTimeout(nexTrackTimer.current);
                    if(nexTrackFired.current === false && managed === 'play' && playback.is_playing) {
                        nexTrackTimer.current = setTimeout(() => {
                            if(nextTrack.current && nexTrackFired.current === false && managed === 'play') {
                                let trackIdToPlay = nextTrack.current.trackData.spotifyId;
                                let trackToPlay = nextTrack.current;
                                trackToPlay.played = new Date();
                                // console.log('add next track to queue', trackIdToPlay);
                                nexTrackFired.current = true;
                                console.log(new Date(), 'Add next track to queue', trackIdToPlay);
                                queueTrack(playlistId, trackIdToPlay).then(async () => {
                                    await updatePlaylist(playlistId, {
                                        [`playedTracks.${trackIdToPlay}`]: trackToPlay
                                    });
                                    setQueuedTrackInfos(trackToPlay);
                                    await removeTrackFromPlaylist(playlistId, trackIdToPlay);
                                }).catch((err) => {
                                    console.log(err);
                                })
                            }
                        }, playback.item.duration_ms - playback.progress_ms < 20000 ? 0 : playback.item.duration_ms - playback.progress_ms - 20000);
                    }
                } else {
                    if(nexTrackTimer.current) clearTimeout(nexTrackTimer.current);
                    setPlaybackState(null);
                    updatePlaylist(playlistId, {
                        playback: {}
                    });
                }
            }).catch((err) => {
                // setLoading(false);
                console.log(err);
            });
        }
    }

    const handleManagePlayer = () => {
        if(managed === 'stop') {
            setManaged('play');
        } else if (managed === 'pause') {
            setManaged('play');
        } else if (managed === 'play') {
            setManaged('pause');
            updatePlaylist(playlistId, {
                playback: {}
            });
            // Reset all
            document.location.reload();
        }
    }
    

    useEffect(() => {
        getCurrentUser().then(userInfos => {
            setUser(userInfos);
        });
    }, []);

    useEffect(() => {
        updatePlayback();
        checkPlayerTimer.current = setInterval(() => {
            updatePlayback();
        }, 10000);

        return () => {
            clearInterval(checkPlayerTimer.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managed]);

    useEffect(() => {
        if(playlist && playlist.tracks && Object.keys(playlist.tracks).length > 0) {
            const nextTrackid = Object.keys(playlist.tracks)[0];
            nextTrack.current = playlist.tracks[nextTrackid];
        }
    }, [playlist]);

    useEffect(() => {
        if(user) {
            try {
                const unsub = onSnapshot(doc(db, "playlists", playlistId), async (doc) => {
                    let playlistData = doc.data();
                    playlistData.id = doc.id;
                    const reorderedTracks = await reorderTracks(playlistData.tracks);
                    playlistData.tracks = reorderedTracks;
                    setPlaylist(playlistData);
                    
                    // Sort playlist.subscribers by sum of votes & tracks sub value.
                    let subscribers = Object.keys(playlistData.subscribers).sort((a, b) => {
                        if(!playlistData.subscribers[b].votes) playlistData.subscribers[b].votes = 0;
                        if(!playlistData.subscribers[b].tracks) playlistData.subscribers[b].tracks = 0;
                        if(!playlistData.subscribers[a].votes) playlistData.subscribers[a].votes = 0;
                        if(!playlistData.subscribers[a].tracks) playlistData.subscribers[a].tracks = 0;
                        return (playlistData.subscribers[b].votes + (playlistData.subscribers[b].tracks * 2)) - (playlistData.subscribers[a].votes + (playlistData.subscribers[a].tracks*2));
                    });
    
                    let sortedSubscribers = {};
                    for(let i = 0; i < subscribers.length; i++) {
                        sortedSubscribers[subscribers[i]] = playlistData.subscribers[subscribers[i]];
                    }
                    
                    playlistData.subscribers = sortedSubscribers;
        
                    let userPlaylistStatusSet = false;
                    Object.keys(playlistData.subscribers).map((subscriber) => {
                        if(subscriber === user.uid) {
                            userPlaylistStatusSet = true;
                            setUserPlaylistStatus(playlistData.subscribers[subscriber].status);
                        }
                        return subscriber;
                    });
    
                    if(!userPlaylistStatusSet) {
                        setUserPlaylistStatus('none');
                    }
                }, (error) => {
                    console.log("Error getting document:", error);
                });
    
                // Manage wake lock
                if('wakeLock' in navigator) {
                    navigator.wakeLock.request('screen').then((wakeLock) => {
                        wakeLock.addEventListener('release', () => {
                            setWakeLockStatus(false);
                        });
                        setWakeLockStatus(true);
                    });
                }
        
                return () => {
                    unsub();
                    // Remove wake lock && listener
                    if ('wakeLock' in navigator) {
                        if (navigator.wakeLock.release) {
                            navigator.wakeLock.release();
                        }
                        setWakeLockStatus(false);
                    }
                    updatePlaylist(playlistId, {
                        playback: null
                    });
                }
            } catch(e) {
                console.log(e)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if(userPlaylistStatus && userPlaylistStatus !== 'owner') {
            // Redirect to playlist
            navigate(`/playlist/${playlistId}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPlaylistStatus]);

    useEffect(() => {
        if(progressTimer.current) clearInterval(progressTimer.current);
        if(playbackState && playbackState.is_playing) {
            progressRef.current = playbackState.progress_ms;
            progressTimer.current = setInterval(() => {
                progressRef.current = progressRef.current + 1000;
                setProgress(progressRef.current);
            }, 1000);
        } else {
            progressRef.current = 0;
            setProgress(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playbackState]);

    const NexTrackItem = ({trackData}) => {
        if(trackData) {
            return <Box>
                <Box style={{ marginLeft: 10, marginTop: 10, columnGap: 10, display: 'flex', alignItems: 'center' }}>
                    {trackData.albumArt && <Box>
                        <img src={trackData.albumArt} alt={trackData.name} style={{ width: 65, height: 65, objectFit: 'cover', backgroundPosition: 'center' }} />
                    </Box>}
                    <Box style={{ marginTop: -10, overflow: 'hidden' }}>
                        {playbackState && playbackState.item && playbackState.item.duration_ms - progress > 50000 ? (
                            <Typography variant="caption">{t('playlist.queue.nexTrackTimer', {time: moment.duration(playbackState.item.duration_ms - progress).humanize()})}</Typography>
                        ) : playbackState && playbackState.item && (playbackState.item.duration_ms - progress) > 20000 ? (
                            <Typography variant="caption">{t('playlist.queue.fewSeconds', {time: moment.duration(playbackState.item.duration_ms - progress).humanize()})}</Typography>
                        ) : (
                            <Typography variant="caption">{t('playlist.queue.nexTrack')}</Typography>
                        )}
                        <Box className="text-truncate"><b>{trackData.name}</b></Box>
                        <Box className="text-truncate">{trackData.artists && trackData.artists.map((artist) => artist).join(', ')}</Box>
                    </Box>
                </Box>
            </Box>
        }
    }

    return <StandardLayout title={playlist && playlist.name && t('playlist.queue.title', { name: playlist?.name })} backButton={`/playlist/${playlistId}`}>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ position: 'relative', overflow: 'hidden' }}>
                <Box sx={{ position: 'relative', overflow: 'hidden', width: '100%', aspectRatio: '1 / 1' }}>
                    <QRCode bgColor="transparent" fgColor="#FFFFFF" level="L" title="Partyfy" value={`${window.location.href.replace(/\/queue$/, '')}`} style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 4,
                        width: '25%',
                        height: '25%',
                        borderRadius: 5
                    }} />
                    <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(18, 18, 18, 0.7)', zIndex: 1 }} />
                    <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'linear-gradient(to top, rgba(18, 18, 18, 1) 10%, rgba(18, 18, 18, 0))', zIndex: 3 }} />
                    {playlist && playlist.image && <img src={playlist.image} alt={playlist.name} style={{ borderRadius: 10, width: '100%', aspectRatio: '1', objectFit: 'cover', backgroundPosition: 'center' }} />}
                    <Box sx={{
                        position: 'absolute', 
                        width: '100%', 
                        top: 16, 
                        left: 16,
                        padding: 1,
                        zIndex: 2
                    }}>
                        <h3 style={{ margin: 0, marginBottom: 10 }}>{t('playlist.player.nexttracks')}</h3>
                        {playlist && playlist.tracks && Object.keys(playlist.tracks).map((track, index) => {
                            if(index < 6) {
                                return <Grid container spacing={2} key={track}>
                                    <Grid item xs={2}>
                                        <img src={playlist.tracks[track].trackData.albumArt} alt={playlist.tracks[track].trackData.name} style={{ width: '100%' }} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Box>
                                            <h4 style={{ margin: 0 }}>{playlist.tracks[track].trackData.name}</h4>
                                            <p style={{ margin: 0 }}>{playlist.tracks[track].trackData.album}</p>
                                            <p style={{ margin: 0 }}>{playlist.tracks[track].trackData.artists.join(', ')}</p>
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                            return null;
                        })}
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        position: 'absolute', 
                        bottom: 16, 
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 4,
                    }}>
                        <IconButton onClick={handleManagePlayer}>
                            {managed === 'play' ? <PauseCircleOutlineIcon style={{ fontSize: 60 }} /> : <PlayCircleOutlineIcon style={{ fontSize: 60 }} />}
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
            {(managed === 'stop' || managed === 'pause') && <Card>
                <CardContent>
                    <Typography variant="h4">{t('playlist.queue.explainTitle')}</Typography>
                    <Typography variant="body">{t('playlist.queue.explainDesc')}</Typography>
                    <ol>
                        <li style={{ fontWeight: 'bold' }}>{t('playlist.queue.step1')}</li>
                        <li style={{ fontWeight: 'bold' }}>{t('playlist.queue.step2')}</li>
                        <li style={{ fontWeight: 'bold' }}>{t('playlist.queue.step3')}</li>
                    </ol>
                    <Typography variant="caption">{t('playlist.queue.tips')}</Typography>
                    <Box >
                        <Button size="small" variant="outlined" sx={{ mt: 1 }} onClick={
                            () => navigate(`/playlist/${playlistId}/player`)
                        }>
                            {t('playlist.queue.partyfyPlayer')}
                        </Button>
                    </Box>
                </CardContent>
            </Card>}
            {(managed === 'play') && playbackState && playbackState.item && <Box>
                    <Card sx={{ display: 'flex', position: 'relative' }}>
                        {playbackState && playbackState.progress_ms > 0 && <LinearProgress variant="determinate" value={(progress / playbackState.item.duration_ms) * 100} style={{ width: '100%', height: 5, position: 'absolute', bottom: 0, left: 0, zIndex: 5 }} />}
                        {playbackState && playbackState.item && playbackState.item.album && playbackState.item.album.images && playbackState.item.album.images[0] && playbackState.item.album.images[0].url && <CardMedia
                            component="img"
                            sx={{ width: 150, height: 150, objectFit: 'cover', backgroundPosition: 'center', display: { xs: 'none', sm: 'block' }}}
                            image={playbackState.item.album.images[0].url}
                            alt={playbackState.item?.name}
                        />}
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Box sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                backgroundColor: "#1E1E1E",
                                borderRadius: 5,
                                padding: 1,
                            }} onClick={() => window.open('https://open.spotify.com/')}>
                                {window.innerWidth < 768 ? <SpotifyGlyphe style={{ width: 30, height: 30 }} /> : <SpotifyLogo style={{ width: 90, height: 30 }} />}
                            </Box>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <h3 style={{ margin: 0 }} className="text-truncate">{playbackState.item?.name}</h3>
                                <h4 style={{ margin: 0 }} className="text-truncate">{playbackState.item?.artists[0].name}</h4>
                                <h5 style={{ margin: 0 }} className="text-truncate">{playbackState.item?.album.name}</h5>
                            </CardContent>
                            <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 2, alignItems: 'center' }}>
                                <Grid item xs={4}>
                                    {playbackState && moment(playbackState.item.duration_ms - progress).format('mm:ss')}
                                </Grid>
                                <Grid item xs={4} sx={{ textAlign: 'center' }}>
                                    {/* {loading && <IconButton sx={{ padding: 1, backgroundColor: "#33333391" }}><CircularProgress size={20} /></IconButton>} */}
                                </Grid>
                                <Grid item xs={4} sx={{ textAlign: 'right' }}>
                                    <IconButton sx={{ padding: 1, backgroundColor: "#33333391", minWidth: 35, minHeight: 35 }} onClick={() => {
                                            if(wakeLockStatus) {
                                                navigator.wakeLock.release();
                                                setWakeLockStatus(false);
                                            } else {
                                                navigator.wakeLock.request('screen').then((wakeLock) => {
                                                    wakeLock.addEventListener('release', () => {
                                                        setWakeLockStatus(false);
                                                    });
                                                    setWakeLockStatus(true);
                                                });
                                            }
                                        }}>
                                        <ScreenLockPortraitIcon sx={{ fontSize: 20 }} style={{ color: wakeLockStatus ? '#00DE83' : 'red' }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Card>
                    <Box>
                        {queuedTrackInfos && <NexTrackItem trackData={queuedTrackInfos.trackData} />}
                        {!queuedTrackInfos && playlist.tracks[Object.keys(playlist.tracks)[0]] && <NexTrackItem trackData={playlist.tracks[Object.keys(playlist.tracks)[0]].trackData} />}
                    </Box>
                </Box>}
                {managed === 'play' && !playbackState && <Card sx={{
                    textAlign: 'center',
                }}>
                    <CardContent>
                        <Typography variant="h5">{t('playlist.queue.explain')}</Typography>
                    </CardContent>
                </Card>}
                <Box sx={{ marginTop: 2 }}>
                    <h1 style={{ textAlign: 'center' }}>{t('playlist.player.subscribers')}</h1>
                    {playlist && playlist.subscribers && Object.keys(playlist.subscribers).map((subscriber, i) => {
                        if(i > 5) return null;
                        return <Grid container spacing={2} key={subscriber} sx={{ alignItems: 'center', mb: 2 }}>
                            <Grid item xs={2}>
                                <Badge badgeContent={i+1} sx={{
                                "& .MuiBadge-badge": {
                                    color: "#333",
                                    backgroundColor: i === 0 ? "#00DE83" : i === 1 ? "#43feb1" : i === 2 ? "#adffdd" : "#FFFFFF"
                                }
                            }}>
                                    <Avatar alt={playlist.subscribers[subscriber].display_name} src={playlist.subscribers[subscriber].picture} />
                                </Badge>
                            </Grid>
                            <Grid item xs={10}>
                                <h2 style={{ margin: 0, lineHeight: '1em' }}>{playlist.subscribers[subscriber].display_name}</h2>
                                <small>
                                    {t('playlist.player.subscribersStats', { 
                                        tracks: playlist.subscribers[subscriber].tracks ? playlist.subscribers[subscriber].tracks : 0,
                                        votes: playlist.subscribers[subscriber].votes ? playlist.subscribers[subscriber].votes : 0
                                    })}
                                </small>
                            </Grid>
                        </Grid>
                    })}
                </Box>
            </Grid>
        </Grid>
    </StandardLayout>;
}