import React, { useContext, useEffect, useState } from 'react';
import { Grid, Box, Avatar, Badge, IconButton, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Slide } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { doc, updateDoc, deleteField, increment } from "firebase/firestore";
import scrollIntoView from 'scroll-into-view-if-needed';
import moment from 'moment';
import 'moment/locale/fr';

import { GlobalContext } from '../../inc/GlobalContext';
import { firestore } from '../../inc/config.js';
import { addTrackToPlaylist } from '../../inc/Playlist.js';
import { getRecommendations } from '../../inc/Spotify.js';

import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CircularProgress from '@mui/material/CircularProgress';
import PauseIcon from '@mui/icons-material/Pause';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as SpotifyIcon } from '../../assets/images/spotify-glyphe.svg';

const avatarSize = 72;

const ConfirmTrackAddDialog = ({open, track, playlist, handleCancel, handleConfirm}) => {
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(false);

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t('playlist.addtrack.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Trans i18nKey="playlist.addtrack.text" values={{track: track.name, playlist: playlist.name}} />
                </DialogContentText>
                {playlist && playlist.playedTracks && playlist.playedTracks[track.id] && (
                    <>
                        <DialogContentText style={{ marginTop: 10, color: 'red', fontWeight: 'bold' }}><Trans i18nKey="playlist.addtrack.important" /></DialogContentText>
                        <DialogContentText><Trans i18nKey="playlist.addtrack.exists" values={{track: track.name, playlist: playlist.name}} /></DialogContentText>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    {t('playlist.addtrack.cancel')}
                </Button>
                <Button onClick={(e) => { setLoading(true); handleConfirm(e); }} color="primary" autoFocus>
                    {loading ? <CircularProgress size={20} /> : t('playlist.addtrack.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const SearchTrackItem = ({ track, playlistId, playlist, setTrackResults, setShowDetails, setDisplaySearch }) => {
    const { audioTrack, setAudioTrack, playbackState, setPlaybackState } = useContext(GlobalContext);
    const [openAddDialog, setOpenAddDialog] = useState(false);

    const addTrack = (e) => {
        e.preventDefault()
        // console.log(playlistId, track, playlist)
        addTrackToPlaylist(playlistId, playlist, track).then(() => {
            setDisplaySearch(false)
            setOpenAddDialog(false)
            setPlaybackState("paused")
            setTrackResults([])
            if(setShowDetails) setShowDetails(null)
            setTimeout(() => {
                if(document.getElementById(track.id)) {
                    scrollIntoView(document.getElementById(track.id), {
                        scrollMode: 'always',
                        block: 'center',
                        inline: 'nearest'
                    });
                    // Add class active fadeIn to successBox
                    const trackItem = document.getElementById(track.id);
                    const successBox = trackItem.getElementsByClassName('successBox')[0];
                    successBox.classList.add('active');

                    setTimeout(() => {
                        successBox.classList.remove('fadeOut');
                        successBox.classList.add('fadeIn');
                        setTimeout(() => {
                            successBox.classList.remove('fadeIn');
                            successBox.classList.add('fadeOut');
                        }, 500);
                        setTimeout(() => {
                            successBox.classList.remove('active');
                        }, 1000);
                    }, 1000);
                }
            }, 500);
        }).catch((error) => {
            console.log('error adding track', error)
            alert(error.message)
        });
    }

    return <Grid container spacing={2} sx={{mt: 1}}>
        <Grid item>
            <Avatar variant="rounded" sx={{ width: avatarSize, height: avatarSize }} src={track.album && track.album.images && track.album.images[0] && track.album.images[0].url} alt={track.name} />
        </Grid>
        <Grid item xs style={{ overflow: 'hidden' }}>
            <Box sx={{fontWeight: 'bold', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {track.name}
            </Box>
            <Box sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {track.artists.map((artist) => artist.name).join(', ')}
            </Box>
            <Box sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                <i>{track.album.name}</i>
            </Box>
        </Grid>
        <Grid item>
            <ConfirmTrackAddDialog open={openAddDialog} track={track} playlist={playlist} handleCancel={() => setOpenAddDialog(false)} handleConfirm={addTrack} />
            {track.preview_url && (
                <>
                    {playbackState === 'playing' && audioTrack === track.preview_url ? (
                        <IconButton edge="end" aria-label="play" onClick={() => setPlaybackState("paused")}>
                            <PauseIcon fontSize='large' />
                        </IconButton>
                    ) : playbackState === 'paused' && audioTrack === track.preview_url ? (
                        <IconButton edge="end" aria-label="play" onClick={() => setPlaybackState("playing")}>
                            <PlayArrowIcon fontSize='large' />
                        </IconButton>
                    ) : (
                        <IconButton edge="end" aria-label="play" onClick={() => setAudioTrack(track.preview_url)}>
                            <PlayArrowIcon fontSize='large' />
                        </IconButton>
                    )}
                </>
            )}
            <IconButton edge="end" aria-label="add" onClick={(e) => {
                if(playlist && playlist.tracks && playlist.tracks[track.id]) {
                    addTrack(e);
                } else {
                    setOpenAddDialog(true)
                }
            }}>
                {playlist && playlist.tracks && playlist.tracks[track.id] ? <ThumbUpOffAltIcon fontSize='large' /> : <AddCircleOutlineIcon fontSize='large' />}
            </IconButton>
        </Grid>
    </Grid>;
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PlaylistTrackItem = ({ trackId, track, playlistId, playlist, setTrackResults, showDetails, setShowDetails, position, setDisplaySearch }) => {
    const { t } = useTranslation();
    const { user, audioTrack, setAudioTrack, playbackState, setPlaybackState } = useContext(GlobalContext);
    const [ vote, setVote ] = useState(0)
    const [ success, setSuccess ] = useState(false)
    const [ displaySuccess, setDisplaySuccess ] = useState(false)
    const [ loadingRecos, setLoadingRecos ] = useState(false)
    const [ recos, setRecos ] = useState([])
    const [ recosDisplay, setRecosDisplay ] = useState([])
    const votes = track.votes ? Object.values(track.votes).reduce((sum, vote) => {
        if(vote.display_name) {
            return sum + vote.value
        } else {
            return sum
        }
    }, 0) : 0;

    useEffect(() => {
        const uid = user.uid
        if (track.votes && track.votes[uid]) {
            if(track.votes[uid].value >= 1) {
                setVote(1)
            } else if (track.votes[uid].value < 1) {
                setVote(-1)
            }
        } else {
            setVote(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [track])

    useEffect(() => {
        if(displaySuccess) {
            setTimeout(() => {
                setSuccess(true)
            }, 500)
            setTimeout(() => {
                setSuccess(false)
            }, 1000)
            setTimeout(() => {
                setDisplaySuccess(false)
            }, 1500)
        }
    }, [displaySuccess])

    useEffect(() => {
        if(showDetails && showDetails === trackId) {
            setLoadingRecos(true)
            getRecommendations(playlistId, trackId).then((recos) => {
                setLoadingRecos(false)
                setRecos(recos)
            }).catch((error) => {
                setRecos([])
                console.log('error getting recommendations', error)
            })
        } else {
            setRecos([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDetails])

    useEffect(() => {
        setRecosDisplay(recos.slice(0,5))
    }, [recos])

    const updateVote = (value) => {
        const db = firestore();
        const playlistRef = doc(db, "playlists", playlistId);
        if(value === 0) {
            updateDoc(playlistRef, {
                [`tracks.${trackId}.votes.${user.uid}`]: deleteField(),
                [`subscribers.${user.uid}.votes`]: increment(-1),
                updated: new Date()
            }).then(async () => {
                scrollIntoView(document.getElementById(trackId), {
                    scrollMode: 'always',
                    block: 'center',
                    inline: 'nearest'
                });
                setDisplaySuccess(true)
            }).catch((error) => {
                console.log('error adding track', error)
            });
        } else {
            updateDoc(playlistRef, {
                [`tracks.${trackId}.votes.${user.uid}`]: {
                    added: new Date(),
                    display_name: user.displayName ? user.displayName : "Anonymous",
                    value: value
                },
                [`subscribers.${user.uid}.votes`]: increment(1),
                updated: new Date()
            }).then(async () => {
                scrollIntoView(document.getElementById(trackId), {
                    scrollMode: 'always',
                    block: 'center',
                    inline: 'nearest'
                });
                setDisplaySuccess(true)
            }).catch((error) => {
                console.log('error adding track', error)
            });
        }
    }

    const handleDeleteTrack = () => {
        const db = firestore();
        const playlistRef = doc(db, "playlists", playlistId);
        var r = window.confirm(t('playlist.track.confirmDelete', { track: track.trackData.name }));
        if (r) {
            updateDoc(playlistRef, {
                [`tracks.${trackId}`]: deleteField(),
                updated: new Date()
            }).then(async () => {
            }).catch((error) => {
                console.log('error removing track', error)
            });
        }
    }

    const TrackContent = () => {
        return (
            <>
                <Grid item sx={{ paddingBottom: '5px' , paddingTop: '5px' }} onClick={() => setShowDetails(trackId)}>
                    <Avatar variant="rounded" sx={{ width: avatarSize, height: avatarSize }} src={track.trackData.albumArt} alt={track.trackData.name} />
                </Grid>
                <Grid item xs sx={{ paddingBottom: '5px' , paddingTop: '5px',  overflow: 'hidden' }}  onClick={() => setShowDetails(trackId)}>
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Box style={{
                            fontWeight: 'normal',
                            fontSize: '0.7rem',
                            marginRight: 5,
                            width: 18,
                            height: 18,
                            minWidth: 18,
                            backgroundColor: '#ccc',
                            color: '#121212',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '50%'

                        }}>{position}</Box> 
                        <Box style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontWeight: 'bold' }}>
                            {track.trackData.name}
                        </Box>
                    </Box>
                    <Box sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                        {track.trackData.artists.join(', ')}
                    </Box>
                    <Box sx={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                        <i>{track.trackData.album}</i>
                    </Box>
                </Grid>
                <Grid item style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingBottom: '5px' , paddingTop: '5px', paddingLeft: 0 }} >
                    <Badge badgeContent={votes.toString()} color={votes && votes > 0 ? "primary" : votes && votes < 0 ? "error" : "secondary"}>
                        {vote === 0 ? <Box>
                            <IconButton edge="end" aria-label="add" onClick={() => updateVote(-1)}>
                                <ThumbDownOffAltIcon fontSize='large' style={{ marginTop: 7}} />
                            </IconButton>
                            <IconButton edge="end" aria-label="add" onClick={() => updateVote(1)}>
                                <ThumbUpOffAltIcon fontSize='large' style={{ marginTop: -7}} />
                            </IconButton>
                        </Box>  : vote === 1 ? <IconButton edge="end" aria-label="add" onClick={() => updateVote(0)}>
                            <ThumbUpIcon fontSize='large' />
                        </IconButton> : vote === -1 ? <IconButton edge="end" aria-label="add" onClick={() => updateVote(0)}>
                            <ThumbDownIcon fontSize='large' />
                        </IconButton> : null}
                    </Badge>
                </Grid>
            </>
        )
    }

    return <Grid container spacing={2} sx={{mt: 1, position: 'relative'}}>
        <Box 
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                zIndex: -1,
                background: 'rgba(0,222,131,0.5)',
                width: 'calc(100% + 16px)',
                height: '110%', 
                marginRight: '-16px',
                marginLeft: '0px',
                transition: 'all 0.5s'
            }} 
            className={`successBox ${displaySuccess ? "active" : ""} ${success ? "fadeIn" : "fadeOut"}`} 
        />
        <Dialog fullScreen TransitionComponent={Transition} open={showDetails === trackId} onClose={() => {
            setPlaybackState("paused")
            setShowDetails(null)
        }} sx={{ 
            background: '#121212',
            '& .MuiPaper-root': {
                background: '#121212'
            }
        }}>
            <DialogContent>
                <Button sx={{position: 'absolute', top: 10, right: 10, cursor: 'pointer', color: '#fff' }} onClick={() => {
                    setPlaybackState("paused")
                    setShowDetails(null)
                }}>
                    <CloseIcon fontSize="large" />
                </Button>
                <Grid container spacing={2} style={{ marginTop: '2em' }}>
                    <TrackContent />
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="caption" color="text.secondary">{t('playlist.track.added', { user: track.addedby.display_name, date: moment(track.added.toDate()).format('LL'), time: moment(track.added.toDate()).format('HH:mm')})}</Typography>
                    </Grid>
                </Grid>
                {track.trackData.preview_url && (
                    <Box sx={{ display: 'flex', mt: 2 }}>
                        {playbackState === 'playing' && audioTrack === track.trackData.preview_url ? (
                            <Button fullWidth aria-label="play" onClick={() => setPlaybackState("paused")}>
                                <SpotifyIcon style={{ width: 30, height: 30 }} /> <PauseIcon fontSize='large' /> {t('playlist.track.preview')}
                            </Button>
                        ) : playbackState === 'paused' && audioTrack === track.trackData.preview_url ? (
                            <Button fullWidth aria-label="play" onClick={() => setPlaybackState("playing")}>
                                <SpotifyIcon style={{ width: 30, height: 30 }} /> <PlayArrowIcon fontSize='large' /> {t('playlist.track.preview')}
                            </Button>
                        ) : (
                            <Button fullWidth aria-label="play" onClick={() => setAudioTrack(track.trackData.preview_url)}>
                                <SpotifyIcon style={{ width: 30, height: 30 }} /> <PlayArrowIcon fontSize='large' /> {t('playlist.track.preview')}
                            </Button>
                        )}
                    </Box>
                )}
                <Grid container>
                    <Grid item xs={12}>
                        {track.votes && Object.keys(track.votes).length === 0 && (
                            <Box>
                                <Typography variant="body2" color="text.secondary">{t('playlist.track.novote')}</Typography>
                            </Box>
                        )}
                        {track.votes && Object.keys(track.votes).length > 0 && (
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="h5" color="text.primary">{t('playlist.track.votes')}</Typography>
                                {Object.keys(track.votes).map((voter) => {
                                    if(!playlist.subscribers[voter]) return null;
                                    return <Box key={voter} sx={{ mt: 2 }}>
                                        <Grid container spacing={2} style={{ width: '100%' }}>
                                            <Grid item xs={3}>
                                                {playlist.subscribers[voter] && playlist.subscribers[voter].picture && (
                                                    <Avatar sx={{ width: avatarSize, height: avatarSize }} src={playlist.subscribers[voter].picture} alt={playlist.subscribers[voter].display_name} />
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="h6" color="text.primary">{playlist.subscribers[voter] ? playlist.subscribers[voter].display_name : track.votes[voter].display_name}</Typography>
                                                <Typography variant="caption" color="text.secondary">
                                                {t('playlist.track.voted', { date: moment(track.votes[voter].added.toDate()).format('LL'), time: moment(track.votes[voter].added.toDate()).format('HH:mm')})}
                                                </Typography>
                                                {/*  {track.votes[voter].value > 0 ? t('playlist.track.upvote') : t('playlist.track.downvote')} */}
                                            </Grid>
                                            <Grid item xs={3} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                                {track.votes[voter].value > 0 ? (
                                                    <ThumbUpIcon />
                                                ) : (
                                                    <ThumbDownIcon />
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                })}
                            </Box>
                        )}
                    </Grid>
                </Grid>
                {loadingRecos && (
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="text.primary">{t('playlist.recommendationsCalculation')}</Typography>
                        </Grid>
                        <Grid item xs={12}  sx={{ mt: 2, textAlign: 'center' }}>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                )}
                {recos.length > 0 && (
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="text.primary">{t('playlist.recommendations')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {recosDisplay.map((reco) => <Box key={reco.id}>
                                <SearchTrackItem setDisplaySearch={setDisplaySearch} track={reco} playlistId={playlistId} playlist={playlist} setTrackResults={setTrackResults} setShowDetails={setShowDetails} />
                            </Box>)}
                            {recos.length > 5 && recosDisplay.length <= 5 && (
                                <Box>
                                    <Button variant="outlined" fullWidth sx={{ mt: 1 }} onClick={() => setRecosDisplay(recos)}>{t('playlist.moreRecommendations')}</Button>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <Grid item xs={12}>
                        <Button color="error" variant="outlined" fullWidth sx={{ mt: 2 }} onClick={handleDeleteTrack}>{t('playlist.track.delete')}</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
        <TrackContent />
    </Grid>;
}

export const SearchArtistItem = ({artist}) => {
    return <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {artist.images && artist.images.length > 0 ? (
            <Avatar src={artist.images && artist.images[0] && artist.images[0].url} alt={artist.name} sx={{ width: 100, height: 100 }} />
        ) : (
            <Avatar sx={{ width: 100, height: 100 }}>
                {artist.name[0]}
            </Avatar>
        )}
        <Box sx={{fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', mt: 1}}>
            <Typography 
                // noWrap 
                sx={{
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
            }}>{artist.name}</Typography>
        </Box>
    </Box>
}

export const SearchAlbumItem = ({album}) => {
    return <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        {album.images && album.images.length > 0 ? (
            <Avatar variant="rounded" src={album.images && album.images[0] && album.images[0].url} alt={album.name} sx={{ width: 100, height: 100 }} />
        ) : (
            <Avatar variant="rounded" sx={{ width: 100, height: 100 }}>
                {album.name[0]}
            </Avatar>
        )}
        <Box sx={{fontWeight: 'bold', textTransform: 'uppercase', textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', mt: 1}}>
            <Typography 
                // noWrap 
                sx={{
                // overflow: 'hidden',
                // textOverflow: 'ellipsis',
            }}>{album.name}</Typography>
        </Box>
    </Box>
}