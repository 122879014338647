import { useEffect, useState, useRef } from 'react';

// Third party
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from '../inc/config';
import { Grid, Box, IconButton, Avatar, Badge, LinearProgress, Alert, Card, CardContent, CardMedia } from '@mui/material';
import moment from 'moment';
import QRCode from "react-qr-code";

// Methods & functions
import { getCurrentUser } from '../inc/User';
import { getSpotifyCredentials, removeTrackFromPlaylist, updatePlaylist, reorderTracks } from '../inc/Playlist';
import { 
    playTrack, 
    getRecommendations, 
    // queueTrack 
} from '../inc/Spotify';

// Components
import { StandardLayout } from '../layouts/standard/StandardLayout';

// assets
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import CircularProgress from '@mui/material/CircularProgress';
import ScreenLockPortraitIcon from '@mui/icons-material/ScreenLockPortrait';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as SpotifyLogo } from '../assets/images/spotify-logo.svg';
import { ReactComponent as SpotifyGlyphe  } from '../assets/images/spotify-glyphe.svg';

export default function Player() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { playlistId } = useParams();
    const [ playlist, setPlaylist ] = useState(null);
    const [ player, setPlayer ] = useState(null);
    const [ userPlaylistStatus, setUserPlaylistStatus ] = useState(null);
    const [ user, setUser] = useState(null);
    const [ playerState, setPlayerState ] = useState(null);
    const [ progress, setProgress ] = useState(0);
    const [ device, setDevice ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ wakeLockStatus, setWakeLockStatus ] = useState(false);
    const [ playerError, setPlayerError ] = useState(null);
    const db = firestore();
    const stateFired = useRef(false);
    const trackTimer = useRef(null);
    const interval = useRef(null);
    const nextTrack = useRef(null);

    const handleNextTrack = async (mode) => {
        if(Object.keys(playlist.tracks).length === 0) return;
        setLoading(true);
        console.log('Playing track', nextTrack.current);
        try {
            await playTrack(playlistId, nextTrack.current, device);
        } catch(e) {
            console.log(e);
            setLoading(false);
            setTimeout(() => {
                handleNextTrack(mode);
            }, 5000);
            return false;

        }
        let currentTrack = playlist.tracks[nextTrack.current];
        if(currentTrack && nextTrack.current) {
            console.log('Set track as played', nextTrack.current, playlist.tracks[nextTrack.current])
            currentTrack.played = new Date();
            await updatePlaylist(playlistId, {
                [`playedTracks.${nextTrack.current}`]: currentTrack
            });
            await removeTrackFromPlaylist(playlistId, nextTrack.current);
        }
        setLoading(false);  
    }

    const handleStartPlaylist = async () => {
        if(Object.keys(playlist.tracks).length === 0) return;
        setLoading(true);
        await playTrack(playlistId, Object.keys(playlist.tracks)[0], device);
        let currentTrack = playlist.tracks[Object.keys(playlist.tracks)[0]];
        currentTrack.played = new Date();
        await updatePlaylist(playlistId, {
            [`playedTracks.${Object.keys(playlist.tracks)[0]}`]: currentTrack
        });
        await removeTrackFromPlaylist(playlistId, Object.keys(playlist.tracks)[0]);
        setLoading(false);
    }

    useEffect(() => {
        if(player) {
            player.setName(`Partyfy - ${playlist.name}`);
            player.connect().then(success => {
                if (success) {
                    console.log('The Web Playback SDK successfully connected to Spotify!');
                } else {
                    console.log('The Web Playback SDK could not connect to Spotify.');
                    alert('The Web Playback SDK could not connect to Spotify.');
                }
            });
            player.addListener('ready', ({ device_id }) => {
                console.log('Ready with Device ID', device_id);
                setDevice(device_id);
            });

            player.addListener('player_state_changed', ({
                position,
                duration,
                paused,
                track_window: { current_track }
            }) => {
                if(stateFired.current === true) return;
                if(interval.current) clearInterval(interval.current);
                stateFired.current = true;
                setTimeout(() => {
                    stateFired.current = false;
                }, 500);
                
                if(paused === false) {
                    setProgress(position);
                    interval.current = setInterval(() => {
                        setProgress((progress) => progress + 100);
                    }, 100);
                }

                setPlayerState({
                    position,
                    duration,
                    paused,
                    current_track
                });
            });

            player.addListener('initialization_error', ({ message }) => { console.error('Failed to initialize', message); setPlayerError(message); setLoading(false); });
            player.addListener('authentication_error', ({ message }) => { console.error('Failed to authenticate', message); setPlayerError(message); setLoading(false); });
            player.addListener('account_error', ({ message }) => { console.error('Failed to validate Spotify account', message); setPlayerError(message); setLoading(false); });
            player.addListener('playback_error', ({ message }) => { console.error('Failed to perform playback', message); setPlayerError(message); setLoading(false); });
        }

        // Remove listners on unmount
        return () => {
            if(player) {
                player.disconnect();
                updatePlaylist(playlistId, {
                    playback: null
                });
                player.removeListener('ready');
                player.removeListener('player_state_changed');
                player.removeListener('initialization_error');
                player.removeListener('authentication_error');
                player.removeListener('account_error');
                player.removeListener('playback_error');
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [player]);

    useEffect(() => {
        if(playerState) {
            if(trackTimer.current) clearTimeout(trackTimer.current);
            if(playerState.paused !== true) {
                console.log("Set next track timer to ", (playerState.duration - playerState.position) - 1000);
                trackTimer.current = setTimeout(() => {
                    console.log('Track ended');
                    handleNextTrack('queue');
                }, (playerState.duration - playerState.position) - 3000);
            }
            
            updatePlaylist(playlistId, {
                playback: {
                    is_playing: !playerState.paused,
                    current_track: playerState.current_track,
                    position: playerState.position,
                    duration: playerState.duration,
                    updated: new Date()
                }
            });

            getRecommendations(playlist.id, playerState.current_track.id).then((data) => {
                updatePlaylist(playlistId, {
                    playback: {
                        is_playing: !playerState.paused,
                        current_track: playerState.current_track,
                        position: playerState.position,
                        duration: playerState.duration,
                        updated: new Date(),
                        recos: data
                    }
                });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerState]);

    useEffect(() => {
        if(user) {
            try {
                const unsub = onSnapshot(doc(db, "playlists", playlistId), async (doc) => {
                    let playlistData = doc.data();
                    playlistData.id = doc.id;
                    const reorderedTracks = await reorderTracks(playlistData.tracks);
                    playlistData.tracks = reorderedTracks;
                    setPlaylist(playlistData);
                    
                    // Sort playlist.subscribers by sum of votes & tracks sub value.
                    let subscribers = Object.keys(playlistData.subscribers).sort((a, b) => {
                        if(!playlistData.subscribers[b].votes) playlistData.subscribers[b].votes = 0;
                        if(!playlistData.subscribers[b].tracks) playlistData.subscribers[b].tracks = 0;
                        if(!playlistData.subscribers[a].votes) playlistData.subscribers[a].votes = 0;
                        if(!playlistData.subscribers[a].tracks) playlistData.subscribers[a].tracks = 0;
                        return (playlistData.subscribers[b].votes + (playlistData.subscribers[b].tracks * 2)) - (playlistData.subscribers[a].votes + (playlistData.subscribers[a].tracks*2));
                    });
    
                    let sortedSubscribers = {};
                    for(let i = 0; i < subscribers.length; i++) {
                        sortedSubscribers[subscribers[i]] = playlistData.subscribers[subscribers[i]];
                    }
                    
                    playlistData.subscribers = sortedSubscribers;
    
                    // setToken(await getSpotifyCredentials(playlistId, user.uid));
        
                    let userPlaylistStatusSet = false;
                    Object.keys(playlistData.subscribers).map((subscriber) => {
                        if(subscriber === user.uid) {
                            userPlaylistStatusSet = true;
                            setUserPlaylistStatus(playlistData.subscribers[subscriber].status);
                        }
                        return subscriber;
                    });
    
                    if(!userPlaylistStatusSet) {
                        setUserPlaylistStatus('none');
                    }
                }, (error) => {
                    console.log("Error getting document:", error);
                });
    
                // Manage wake lock
                if('wakeLock' in navigator) {
                    navigator.wakeLock.request('screen').then((wakeLock) => {
                        wakeLock.addEventListener('release', () => {
                            setWakeLockStatus(false);
                        });
                        setWakeLockStatus(true);
                    });
                }
        
                return () => {
                    unsub();
                    // Remove wake lock && listener
                    if ('wakeLock' in navigator) {
                        if (navigator.wakeLock.release) {
                            navigator.wakeLock.release();
                        }
                        setWakeLockStatus(false);
                    }
                    if (trackTimer.current) clearTimeout(trackTimer.current);
                    updatePlaylist(playlistId, {
                        playback: null
                    });
                }
            } catch(e) {
                console.log(e)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if(user && playlist && !player) {
            window.onSpotifyWebPlaybackSDKReady = () => {
                console.log('Create player')
                setPlayer(new window.Spotify.Player({
                    name: 'Web Playback SDK Quick Start Player',
                    getOAuthToken: async cb => {
                        console.log('Get OAuth Token for spotify');
                        getSpotifyCredentials(playlistId, user.uid).then(token => {
                            cb(token);
                        });
                    },
                    volume: 1,
                    enableMediaSession: true
                }));
            }
    
            window.onSpotifyWebPlaybackSDKError = (error) => {
                console.error('Web Playback SDK Error:', error.message);
            }

            const script = document.createElement('script');
            script.src = 'https://sdk.scdn.co/spotify-player.js';
            script.async = true;
            document.body.appendChild(script);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlist]);

    useEffect(() => {
        getCurrentUser().then(userInfos => {
            setUser(userInfos);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(userPlaylistStatus && userPlaylistStatus !== 'owner') {
            // Redirect to playlist
            navigate(`/playlist/${playlistId}`);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPlaylistStatus]);

    useEffect(() => {
        console.log('Next track to play', playlist && playlist.tracks && Object.keys(playlist.tracks)[0]);
        nextTrack.current = playlist && playlist.tracks && Object.keys(playlist.tracks)[0];
    }, [playlist]);

    return <StandardLayout title={playlist && playlist.name && t('playlist.player.title', { name: playlist?.name })} backButton={`/playlist/${playlistId}`} addBottomPadding={playlist?.playback && playlist?.playback.is_playing}>
        {!device && !playerError && <Box sx={{ textAlign: 'center', marginTop: 20 }}><CircularProgress /></Box>}
        {playerError && <Alert icon={<CloseIcon fontSize="inherit" />} severity="error">
            {t('playlist.player.error', { error: playerError })}
        </Alert>}
        {device && <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Box sx={{ position: 'relative', overflow: 'hidden', width: '100%', aspectRatio: '1 / 1' }}>
                    <QRCode bgColor="transparent" fgColor="#FFFFFF" level="L" title="Partyfy" value={`${window.location.href.replace(/\/player$/, '')}`} style={{
                        position: 'absolute',
                        top: 16,
                        right: 0,
                        zIndex: 4,
                        width: '25%',
                        height: '25%',
                        borderRadius: 5
                    }} />
                    <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(18, 18, 18, 0.7)', zIndex: 1 }} />
                    <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'linear-gradient(to top, rgba(18, 18, 18, 1) 10%, rgba(18, 18, 18, 0))', zIndex: 3 }} />
                    {device && <img src={playlist.image} alt={playlist.name} style={{ borderRadius: 10, width: '100%', aspectRatio: '1', objectFit: 'cover', backgroundPosition: 'center' }} />}
                    {device && <Box sx={{
                        position: 'absolute', 
                        width: '100%', 
                        top: 16, 
                        left: 16,
                        padding: 1,
                        zIndex: 2
                    }}>
                        <h3 style={{ margin: 0, marginBottom: 10 }}>{t('playlist.player.nexttracks')}</h3>
                        {playlist && playlist.tracks && Object.keys(playlist.tracks).map((track, index) => {
                            if(index < 6) {
                                return <Grid container spacing={2} key={track}>
                                    <Grid item xs={2}>
                                        <img src={playlist.tracks[track].trackData.albumArt} alt={playlist.tracks[track].trackData.name} style={{ width: '100%' }} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Box>
                                            <h4 style={{ margin: 0 }}>{playlist.tracks[track].trackData.name}</h4>
                                            <p style={{ margin: 0 }}>{playlist.tracks[track].trackData.album}</p>
                                            <p style={{ margin: 0 }}>{playlist.tracks[track].trackData.artists.join(', ')}</p>
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                            return null;
                        })}
                    </Box>}
                    {!playerState && device && <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        position: 'absolute', 
                        bottom: 16, 
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 4,
                    }}>
                        {loading && <IconButton sx={{ padding: 1, backgroundColor: "#33333391" }}>
                            <CircularProgress size={60} />
                        </IconButton>}
                        {!loading && <IconButton sx={{ padding: 1, backgroundColor: "#33333391" }} onClick={handleStartPlaylist}>
                            <PlayArrowIcon sx={{ fontSize: 60 }} />
                        </IconButton>}
                    </Box>}
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                {playerState && playerState.current_track && <Box>
                    <Card sx={{ display: 'flex', position: 'relative' }}>
                        {playerState && progress > 0 && <LinearProgress variant="determinate" value={(progress / playerState.duration) * 100} style={{ width: '100%', height: 5, position: 'absolute', bottom: 0, left: 0, zIndex: 5 }} />}
                        {playerState && playerState.current_track && playerState.current_track.album && playerState.current_track.album.images && playerState.current_track.album.images[0] && playerState.current_track.album.images[0].url && <CardMedia
                            component="img"
                            sx={{ width: 150, display: { xs: 'none', sm: 'block' }}}
                            image={playerState.current_track.album.images[0].url}
                            alt={playerState.current_track?.name}
                        />}
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Box sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                            }} onClick={() => window.open('https://open.spotify.com/')}>
                                {window.innerWidth < 768 ? <SpotifyGlyphe style={{ width: 30, height: 30 }} /> : <SpotifyLogo style={{ width: 90, height: 30 }} />}
                            </Box>
                            <CardContent sx={{ flex: '1 0 auto' }}>
                                <h3 style={{ margin: 0 }}>{playerState.current_track?.name}</h3>
                                <h4 style={{ margin: 0 }}>{playerState.current_track?.artists[0].name}</h4>
                                <h5 style={{ margin: 0 }}>{playerState.current_track?.album.name}</h5>
                            </CardContent>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                                paddingBottom: '16px',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: 40,
                                }}>
                                    {!loading && playerState && moment(playerState.duration - progress).format('mm:ss')}
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    columnGap: '16px'
                                }}>
                                    <Box sx={{ minWidth: 35 }}>
                                        <IconButton sx={{ padding: 1, backgroundColor: "#33333391", minWidth: 35, minHeight: 35 }} onClick={() => {
                                                if(wakeLockStatus) {
                                                    navigator.wakeLock.release();
                                                    setWakeLockStatus(false);
                                                } else {
                                                    navigator.wakeLock.request('screen').then((wakeLock) => {
                                                        wakeLock.addEventListener('release', () => {
                                                            setWakeLockStatus(false);
                                                        });
                                                        setWakeLockStatus(true);
                                                    });
                                                }
                                            }}>
                                            <ScreenLockPortraitIcon sx={{ fontSize: 20 }} style={{ color: wakeLockStatus ? '#00DE83' : 'red' }} />
                                        </IconButton>
                                    </Box>
                                    <Box sx={{ minWidth: 35, position: 'relative' }}>
                                        {loading && <IconButton sx={{ padding: 1, backgroundColor: "#33333391" }}><CircularProgress size={20} /></IconButton>}
                                        {!loading && player && playerState && playerState.paused && <IconButton sx={{ padding: 1, backgroundColor: "#33333391" }} onClick={() => player.resume()}><PlayArrowIcon sx={{ fontSize: 20 }} /></IconButton>}
                                        {!loading && player && playerState && !playerState.paused && <IconButton sx={{ padding: 1, backgroundColor: "#33333391" }} onClick={() => player.pause()}><PauseIcon sx={{ fontSize: 20 }} /></IconButton>}
                                    </Box>
                                    <Box sx={{ minWidth: 35 }}>
                                        {!loading && player && playerState && <IconButton sx={{ padding: 1, backgroundColor: "#33333391" }} onClick={handleNextTrack}><SkipNextIcon sx={{ fontSize: 20 }} /></IconButton>}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Box>}
                <Box sx={{ marginTop: 2 }}>
                    <h1 style={{ textAlign: 'center' }}>{t('playlist.player.subscribers')}</h1>
                    {playlist && playlist.subscribers && Object.keys(playlist.subscribers).map((subscriber, i) => {
                        if(i > 5) return null;
                        return <Grid container spacing={2} key={subscriber} sx={{ alignItems: 'center', mb: 2 }}>
                            <Grid item xs={2}>
                                <Badge badgeContent={i+1} sx={{
                                "& .MuiBadge-badge": {
                                    color: "#333",
                                    backgroundColor: i === 0 ? "#00DE83" : i === 1 ? "#43feb1" : i === 2 ? "#adffdd" : "#FFFFFF"
                                }
                            }}>
                                    <Avatar alt={playlist.subscribers[subscriber].display_name} src={playlist.subscribers[subscriber].picture} />
                                </Badge>
                            </Grid>
                            <Grid item xs={10}>
                                <h2 style={{ margin: 0, lineHeight: '1em' }}>{playlist.subscribers[subscriber].display_name}</h2>
                                <small>
                                    {t('playlist.player.subscribersStats', { 
                                        tracks: playlist.subscribers[subscriber].tracks ? playlist.subscribers[subscriber].tracks : 0,
                                        votes: playlist.subscribers[subscriber].votes ? playlist.subscribers[subscriber].votes : 0
                                    })}
                                </small>
                            </Grid>
                        </Grid>
                    })}
                </Box>
            </Grid>
        </Grid>}
    </StandardLayout>
}